import React from 'react';
import {
  ScreenContainer,
  Surface,
  Table,
  TableCell,
  TableRow,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAdminApi from '../apis/XanoAdminApi.js';
import BalanceTableRowBlock from '../components/BalanceTableRowBlock';
import WalletViewBlock from '../components/WalletViewBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Sidebar from '../custom-files/Sidebar';
import getTableRowColor from '../global-functions/getTableRowColor';
import transformArrWithIndex from '../global-functions/transformArrWithIndex';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const BalanceScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [centValue, setCentValue] = React.useState('00');
  const [dollarValue, setDollarValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [noteValue, setNoteValue] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const totalBalance = (balance, reservedBalance) => {
    return parseInt(balance) + parseInt(reservedBalance);
  };

  const totalAmount = () => {
    return dollarValue * 100 + (parseInt(centValue) | 0);
  };

  const verifyForm = () => {
    const dollar = parseInt(dollarValue);

    if (isNaN(dollar)) {
      setErrorMessage('Please enter a valid number');
      return false;
    }

    if (dollar === 0) {
      setErrorMessage('Please enter a number higher than 0');
      return false;
    }

    setErrorMessage('');
    return true;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!Constants['MA_AUTH_TOKEN']) {
        navigation.navigate('AuthLoginScreen');
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth({ flexDirection: 'row' }, dimensions.width)}
    >
      {/* Sidebar */}
      <Utils.CustomCodeErrorBoundary>
        <Sidebar.Menu
          currentScreen="balance"
          navigation={props.navigation}
          collapsed={Variables.SIDEBAR_MODE === 'collapsed'}
        />
      </Utils.CustomCodeErrorBoundary>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth({ flex: 1, margin: 20 }, dimensions.width)}
      >
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['ScreenHeader'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['ScreenHeader'].style,
            dimensions.width
          )}
        >
          {'Balance'}
        </Text>
        {/* Container */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={1}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Surface'].style,
              {
                backgroundColor: palettes.Brand.Surface,
                borderRadius: 20,
                flex: 1,
                overflow: 'hidden',
              }
            ),
            dimensions.width
          )}
        >
          <WalletViewBlock />
          {/* TableView */}
          <View>
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    fontFamily: 'SpaceGrotesk_600SemiBold',
                    fontSize: 20,
                    marginLeft: 16,
                    marginRight: 16,
                  }
                ),
                dimensions.width
              )}
            >
              {'Transaction History'}
            </Text>

            <XanoAdminApi.FetchGetTransactionsGET
              refresh={Constants['refresh_balance']}
            >
              {({ loading, error, data, refetchGetTransactions }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <Table
                    borderColor={theme.colors.border.brand}
                    borderStyle={'solid'}
                    borderWidth={1}
                    cellHorizontalPadding={10}
                    cellVerticalPadding={10}
                    data={transformArrWithIndex(fetchData)}
                    drawBottomBorder={false}
                    drawEndBorder={false}
                    drawStartBorder={false}
                    keyExtractor={(tableData, index) =>
                      tableData?.id ??
                      tableData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(tableData)
                    }
                    listKey={'mDfdsbg6'}
                    renderItem={({ item, index }) => {
                      const tableData = item;
                      return (
                        <>
                          <TableRow
                            drawEndBorder={false}
                            drawTopBorder={false}
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            isTableHeader={true}
                          >
                            {/* TypeCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'TableHeaderCell'
                                  ].style,
                                  { maxWidth: 75 }
                                ),
                                dimensions.width
                              )}
                            />
                            {/* DateCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Date'}
                              </Text>
                            </TableCell>
                            {/* Description */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Description'}
                              </Text>
                            </TableCell>
                            {/* AmountCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Amount'}
                              </Text>
                            </TableCell>
                            {/* Details */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Details'}
                              </Text>
                            </TableCell>
                          </TableRow>
                          <BalanceTableRowBlock
                            amount={tableData?.amount}
                            description={tableData?.description}
                            related_transactions={
                              tableData?.related_transaction
                            }
                            type={tableData?.type}
                          />
                        </>
                      );
                    }}
                    showsVerticalScrollIndicator={true}
                    {...GlobalStyles.TableStyles(theme)['Table'].props}
                    drawTopBorder={false}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TableStyles(theme)['Table'].style,
                      dimensions.width
                    )}
                  />
                );
              }}
            </XanoAdminApi.FetchGetTransactionsGET>
          </View>
        </Surface>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(BalanceScreen);

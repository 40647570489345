import React from 'react';
import {
  Button,
  Checkbox,
  CheckboxRow,
  Circle,
  DatePicker,
  Icon,
  IconButton,
  Link,
  NumberInput,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Slider,
  Surface,
  Table,
  TableCell,
  TableRow,
  TextInput,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { H3 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import { ActivityIndicator, Image, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAdminApi from '../apis/XanoAdminApi.js';
import WalletViewBlock from '../components/WalletViewBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Sidebar from '../custom-files/Sidebar';
import ShowToastMessage from '../global-functions/ShowToastMessage';
import centToDollar from '../global-functions/centToDollar';
import dateToTimestamp from '../global-functions/dateToTimestamp';
import divide from '../global-functions/divide';
import dollarToCent from '../global-functions/dollarToCent';
import getTableRowColor from '../global-functions/getTableRowColor';
import refreshWallet from '../global-functions/refreshWallet';
import splitByComma from '../global-functions/splitByComma';
import transformArrWithIndex from '../global-functions/transformArrWithIndex';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as DateUtils from '../utils/DateUtils';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';

const AdsManagementScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const getNDaysFromNow = n => {
    var date = new Date();
    date.setDate(date.getDate() + n);
    return date;
  };
  const [adIdToEdit, setAdIdToEdit] = React.useState(0);
  const [adNameValue, setAdNameValue] = React.useState('');
  const [ad_to_edit_budget, setAd_to_edit_budget] = React.useState(null);
  const [ad_to_toggle, setAd_to_toggle] = React.useState(null);
  const [attachment_to_preview, setAttachment_to_preview] =
    React.useState(null);
  const [attachmentsValue, setAttachmentsValue] = React.useState([]);
  const [budgetValue, setBudgetValue] = React.useState(
    parseInt(centToDollar(Constants['MA_BALANCE']), 10)
  );
  const [currently_toggling_id, setCurrently_toggling_id] =
    React.useState(null);
  const [displayAt_homepage, setDisplayAt_homepage] = React.useState(true);
  const [displayAt_relatedTags, setDisplayAt_relatedTags] =
    React.useState(true);
  const [displayAt_searchResults, setDisplayAt_searchResults] =
    React.useState(true);
  const [displayAt_userProfiles, setDisplayAt_userProfiles] =
    React.useState(true);
  const [displayInRandomOrderValue, setDisplayInRandomOrderValue] =
    React.useState(false);
  const [endDateValue, setEndDateValue] = React.useState(getNDaysFromNow(3));
  const [errorMessage, setErrorMessage] = React.useState('');
  const [formMode, setFormMode] = React.useState('');
  const [isActiveValue, setIsActiveValue] = React.useState(true);
  const [isCreating, setIsCreating] = React.useState(false);
  const [keywordsValue, setKeywordsValue] = React.useState('');
  const [startDateValue, setStartDateValue] = React.useState(new Date());
  const [checkboxRowValue, setCheckboxRowValue] = React.useState(undefined);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [date, setDate] = React.useState(new Date());
  const addAttachment = file => {
    function getFileType(base64String) {
      // Pattern to extract MIME type from base64 string
      var regex = /^data:(.*?);base64,/;

      // Extract MIME type
      var match = base64String.match(regex);

      if (match && match.length > 1) {
        var mimeType = match[1];

        // Check if the MIME type is for image
        if (mimeType.startsWith('image/')) {
          return 'image';
        }
        // Check if the MIME type is for video
        else if (mimeType.startsWith('video/')) {
          return 'video';
        }
        // Other file types
        else {
          return 'other';
        }
      } else {
        return 'unknown';
      }
    }

    function generateUniqueId() {
      const timestamp = new Date().getTime();
      const randomNumber = Math.floor(Math.random() * 1000);
      return 'newItem-' + timestamp.toString() + randomNumber.toString();
    }

    const fileType = getFileType(file);

    setAttachmentsValue(prev => {
      console.log(prev);

      if (fileType === 'image') {
        return [
          ...prev,
          {
            id: generateUniqueId(),
            link: 'https://',
            ad_attachment: {
              url: file,
              type: 'image',
            },
          },
        ];
      }

      if (fileType === 'video') {
        return [
          ...prev,
          {
            id: generateUniqueId(),
            link: 'https://',
            ad_attachment: {
              url: file,
              type: 'video',
            },
          },
        ];
      }
    });
  };

  const clearForm = () => {
    setAdNameValue('');
    setStartDateValue(new Date());
    setEndDateValue(getNDaysFromNow(3));
    setIsActiveValue(true);
    setKeywordsValue('');
    setDisplayAt_homepage(true);
    setDisplayAt_relatedTags(true);
    setDisplayAt_searchResults(true);
    setDisplayAt_userProfiles(true);
    setAttachmentsValue([]);
    setDisplayInRandomOrderValue(true);
  };

  const convertedAttachmentsValue = () => {
    return attachmentsValue.map(a => {
      if (a.id.toString().startsWith('newItem')) {
        return {
          id: a.id,
          link: a.link,
          file: a.ad_attachment.url,
        };
      }

      return a;
    });
  };

  const displayAtArr = () => {
    const arr = [];
    if (displayAt_homepage) arr.push('homepage');
    if (displayAt_searchResults) arr.push('search_results');
    if (displayAt_relatedTags) arr.push('related_tags');
    if (displayAt_userProfiles) arr.push('user_profiles');
    return arr;
  };

  const displayAtLabel = val => {
    return val === 'homepage'
      ? 'Homepage'
      : val === 'search_results'
      ? 'Search R.'
      : val === 'related_tags'
      ? 'Related'
      : val === 'user_profiles'
      ? 'Profiles'
      : '';
  };

  const flattenKeywords = keywords => {
    return keywords.map(k => k.keyword).join(', ');
  };

  const removeFromArr = (arr, item) => {
    return arr.filter(it => it.id !== item.id);
  };

  const showActivateButton = (id, is_active) => {
    if (is_active) return false;

    if (currently_toggling_id === id) return false;

    return true;
  };

  const sum = (val1, val2) => {
    return val1 + val2;
  };

  const timeStampToReadable = timestamp => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  const updateLinkOfAttachment = (attachmentId, newValue) => {
    setAttachmentsValue(prev => {
      for (let a of prev) {
        if (a.id === attachmentId) {
          a.link = newValue;
        }
      }

      return prev;
    });
  };

  const validForm = () => {
    if (!adNameValue) {
      ShowToastMessage('error', 'Error', 'Ad Name is required');
      setErrorMessage('Ad Name is required');
      return false;
    }

    if (!startDateValue) {
      ShowToastMessage('error', 'Error', 'Start Date is required');
      setErrorMessage('Start Date is required');
      return false;
    }

    // if (!endDateValue) {
    //   ShowToastMessage('error', 'Error', 'End Date is required');
    //   setErrorMessage('End Date is required');
    //   return false;
    // }

    if (formMode === 'add' && budgetValue === 0) {
      ShowToastMessage('error', 'Error', 'Budget is required');
      setErrorMessage('Budget is required');
      return false;
    }

    if (!keywordsValue) {
      ShowToastMessage('error', 'Error', 'Keywords is required');
      setErrorMessage('Keywords is required');
      return false;
    }

    if (!attachmentsValue) {
      ShowToastMessage('error', 'Error', 'Attachments is required');
      setErrorMessage('Attachments is required');
      return false;
    }

    if (attachmentsValue.length < 1) {
      ShowToastMessage('error', 'Error', 'Attachments is required');
      setErrorMessage('Attachments is required');
      return false;
    }

    for (let a of attachmentsValue) {
      if (!a.link) {
        ShowToastMessage('error', 'Error', 'Attachment Link is required');
        setErrorMessage('Attachment Link is required');
        return false;
      }

      const urlRegex =
        /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?(\?[;&\w\.\=\-\%]*)?(#[\w\-]*)?$/i;
      console.log(a.link, urlRegex.test(a.link));
      if (!urlRegex.test(a.link)) {
        // ShowToastMessage('error', 'Error', 'Attachment Link is required');
        setErrorMessage('A valid attachment Link is required');
        return false;
      }
    }

    setErrorMessage('');
    return true;
  };
  const xanoAdminToggleActiveGET = XanoAdminApi.useToggleActiveGET();
  const xanoAdminCreateNewAdPOST = XanoAdminApi.useCreateNewAdPOST();
  const xanoAdminUpdateAdPOST = XanoAdminApi.useUpdateAdPOST();
  const xanoAdminUpdateAdBudgetPATCH = XanoAdminApi.useUpdateAdBudgetPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!Constants['MA_AUTH_TOKEN']) {
        navigation.navigate('AuthLoginScreen');
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth({ flexDirection: 'row' }, dimensions.width)}
    >
      {/* Sidebar */}
      <Utils.CustomCodeErrorBoundary>
        <Sidebar.Menu
          currentScreen="ads_management"
          navigation={props.navigation}
          collapsed={Variables.SIDEBAR_MODE === 'collapsed'}
        />
      </Utils.CustomCodeErrorBoundary>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth({ flex: 1, margin: 20 }, dimensions.width)}
      >
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['ScreenHeader'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['ScreenHeader'].style,
            dimensions.width
          )}
        >
          {'Ads Management'}
        </Text>
        {/* Container */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={1}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Surface'].style,
              {
                backgroundColor: palettes.Brand.Surface,
                borderRadius: 20,
                flex: 1,
                overflow: 'hidden',
              }
            ),
            dimensions.width
          )}
        >
          <WalletViewBlock />
          <SimpleStyleScrollView
            bounces={true}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsVerticalScrollIndicator={true}
            horizontal={true}
            showsHorizontalScrollIndicator={true}
          >
            <XanoAdminApi.FetchGetMyAdsGET>
              {({ loading, error, data, refetchGetMyAds }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <Table
                    borderColor={theme.colors.border.brand}
                    borderStyle={'solid'}
                    cellHorizontalPadding={10}
                    cellVerticalPadding={10}
                    data={transformArrWithIndex(fetchData)}
                    drawBottomBorder={false}
                    drawEndBorder={false}
                    drawStartBorder={false}
                    keyExtractor={(tableData, index) =>
                      tableData?.id ??
                      tableData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(tableData)
                    }
                    listKey={'5Kt3ncsa'}
                    renderItem={({ item, index }) => {
                      const tableData = item;
                      return (
                        <>
                          {/* Header */}
                          <TableRow
                            drawEndBorder={false}
                            drawTopBorder={false}
                            borderStyle={'solid'}
                            borderWidth={0}
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            isTableHeader={true}
                          >
                            {/* IdCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              borderStyle={'solid'}
                              borderWidth={0}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'TableHeaderCell'
                                  ].style,
                                  { maxWidth: 35, minWidth: 35 }
                                ),
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'ID'}
                              </Text>
                            </TableCell>
                            {/* NameCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              borderStyle={'solid'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Name'}
                              </Text>
                            </TableCell>
                            {/* DateCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              borderStyle={'solid'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Start Date'}
                              </Text>
                            </TableCell>
                            {/* StatsCell */}
                            <>
                              {!(
                                Constants['MA_USER_DATA']?.role === 'admin'
                              ) ? null : (
                                <TableCell
                                  drawBottomBorder={false}
                                  drawStartBorder={false}
                                  drawTopBorder={false}
                                  {...GlobalStyles.TableCellStyles(theme)[
                                    'TableHeaderCell'
                                  ].props}
                                  borderStyle={'solid'}
                                  drawEndBorder={false}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TableCellStyles(theme)[
                                        'TableHeaderCell'
                                      ].style,
                                      {
                                        width: {
                                          minWidth: Breakpoints.Desktop,
                                          value: 200,
                                        },
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'TableHeaderCellText'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'TableHeaderCellText'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Stats'}
                                  </Text>
                                </TableCell>
                              )}
                            </>
                            {/* IsActiveCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              borderStyle={'solid'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'TableHeaderCell'
                                  ].style,
                                  { maxWidth: 65, minWidth: 65 }
                                ),
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Active'}
                              </Text>
                            </TableCell>
                            {/* BudgetCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              borderStyle={'solid'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Remaining Budget'}
                              </Text>
                            </TableCell>
                            {/* KeywordsCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              borderStyle={'solid'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Keywords'}
                              </Text>
                            </TableCell>
                            {/* DisplayAtCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              borderStyle={'solid'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Display At'}
                              </Text>
                            </TableCell>
                            {/* AttachmentsCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              borderStyle={'solid'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Attachments'}
                              </Text>
                            </TableCell>
                            {/* ActionsCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].props}
                              borderStyle={'solid'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'TableHeaderCell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'TableHeaderCellText'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'TableHeaderCellText'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Actions'}
                              </Text>
                            </TableCell>
                          </TableRow>
                          {/* BodyRow */}
                          <TableRow
                            drawEndBorder={false}
                            drawTopBorder={false}
                            isTableHeader={false}
                            borderStyle={'dashed'}
                            borderWidth={0}
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: {
                                  minWidth: Breakpoints.Desktop,
                                  value: getTableRowColor(tableData?.rowIndex),
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {/* IdCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { maxWidth: 35, minWidth: 35 }
                                ),
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {tableData?.id}
                              </Text>
                            </TableCell>
                            {/* NameCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'Table Cell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {tableData?.ad_name}{' '}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        fontFamily: {
                                          minWidth: Breakpoints.Desktop,
                                          value: 'SpaceGrotesk_500Medium',
                                        },
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'('}
                                  {tableData?._creator?.handle}
                                  {')'}
                                </Text>
                              </Text>
                            </TableCell>
                            {/* DateCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'Table Cell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {timeStampToReadable(tableData?.start_date)}
                              </Text>
                            </TableCell>
                            {/* StatsCell */}
                            <>
                              {!(
                                Constants['MA_USER_DATA']?.role === 'admin'
                              ) ? null : (
                                <TableCell
                                  drawBottomBorder={false}
                                  drawStartBorder={false}
                                  drawTopBorder={false}
                                  {...GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].props}
                                  borderStyle={'dashed'}
                                  drawEndBorder={false}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].style,
                                      {
                                        alignItems: 'stretch',
                                        flexDirection: 'column',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* Distributed */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Label */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            fontFamily:
                                              'SpaceGrotesk_600SemiBold',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Distributed: '}
                                    </Text>
                                    {/* Data */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        tableData?._ads_statistics_of_ads?.[0]
                                          ?.distribution_count
                                      }
                                    </Text>
                                  </View>
                                  {/* Viewed */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { justifyContent: 'space-between' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Label */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            fontFamily:
                                              'SpaceGrotesk_600SemiBold',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Viewed: '}
                                    </Text>
                                    {/* Data */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        tableData?._ads_statistics_of_ads?.[0]
                                          ?.view_count
                                      }
                                    </Text>
                                  </View>
                                  {/* Clicked */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { justifyContent: 'space-between' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Label */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            fontFamily:
                                              'SpaceGrotesk_600SemiBold',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Clicked: '}
                                    </Text>
                                    {/* Data */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        tableData?._ads_statistics_of_ads?.[0]
                                          ?.click_count
                                      }
                                    </Text>
                                  </View>
                                </TableCell>
                              )}
                            </>
                            {/* IsActiveCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { maxWidth: 65, minWidth: 65 }
                                ),
                                dimensions.width
                              )}
                            >
                              <Checkbox
                                defaultValue={tableData?.is_active}
                                disabled={true}
                              />
                            </TableCell>
                            {/* BudgetCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'Table Cell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'$'}
                                {centToDollar(tableData?.remaining_budget)}
                                {' / $'}
                                {centToDollar(tableData?.budget)}
                              </Text>
                            </TableCell>
                            {/* KeywordsCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              cellHorizontalPadding={0}
                              cellVerticalPadding={1}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { height: 100 }
                                ),
                                dimensions.width
                              )}
                            >
                              <SimpleStyleFlatList
                                data={tableData?._ads_keywords_of_ads}
                                horizontal={false}
                                inverted={false}
                                keyExtractor={(listData, index) =>
                                  listData?.id ??
                                  listData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={JSON.stringify(
                                  tableData?._ads_keywords_of_ads
                                )}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listData = item;
                                  return (
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {'* '}
                                      {listData?.keyword}
                                    </Text>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    flexDirection: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'row',
                                    },
                                    flexWrap: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'wrap',
                                    },
                                  },
                                  dimensions.width
                                )}
                              />
                            </TableCell>
                            {/* DisplayAtCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { height: 100 }
                                ),
                                dimensions.width
                              )}
                            >
                              <SimpleStyleFlatList
                                data={tableData?.displayed_at}
                                horizontal={false}
                                inverted={false}
                                keyExtractor={(listData, index) =>
                                  listData?.id ??
                                  listData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={JSON.stringify(
                                  tableData?.displayed_at
                                )}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listData = item;
                                  return (
                                    <>
                                      {!listData ? null : (
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'* '}
                                          {displayAtLabel(listData)}
                                        </Text>
                                      )}
                                    </>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                              />
                            </TableCell>
                            {/* AttachmentsCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { maxHeight: 90 }
                                ),
                                dimensions.width
                              )}
                            >
                              <SimpleStyleFlatList
                                data={tableData?._ad_attachments}
                                inverted={false}
                                keyExtractor={(listData, index) =>
                                  listData?.id ??
                                  listData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={JSON.stringify(
                                  tableData?._ad_attachments
                                )}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listData = item;
                                  return (
                                    <Pressable
                                      onPress={() => {
                                        try {
                                          setAttachment_to_preview(listData);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            borderColor:
                                              theme.colors.foreground.brand,
                                            borderRadius: 6,
                                            borderWidth: 1,
                                            justifyContent: 'center',
                                            marginRight: 4,
                                            overflow: 'hidden',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <>
                                          {!(
                                            listData?.ad_attachment?.type ===
                                            'image'
                                          ) ? null : (
                                            <Image
                                              resizeMode={'cover'}
                                              {...GlobalStyles.ImageStyles(
                                                theme
                                              )['Image'].props}
                                              source={imageSource(
                                                `${listData?.ad_attachment?.url}?tpl=small`
                                              )}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'].style,
                                                  { height: 50, width: 50 }
                                                ),
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.ad_attachment?.type ===
                                            'video'
                                          ) ? null : (
                                            <Icon
                                              name={'Ionicons/videocam'}
                                              size={40}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  marginBottom: 5,
                                                  marginLeft: 5,
                                                  marginRight: 5,
                                                  marginTop: 5,
                                                },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                      </View>
                                    </Pressable>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                horizontal={true}
                              />
                            </TableCell>
                            {/* ActionsCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TableCellStyles(theme)[
                                  'Table Cell'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* EditButton */}
                                <IconButton
                                  onPress={() => {
                                    try {
                                      setFormMode('edit');
                                      setAdIdToEdit(tableData?.id);
                                      setAdNameValue(tableData?.ad_name);
                                      setStartDateValue(
                                        new Date(tableData?.start_date)
                                      );
                                      setEndDateValue(
                                        new Date(tableData?.end_date)
                                      );
                                      setIsActiveValue(tableData?.is_active);
                                      setKeywordsValue(
                                        flattenKeywords(
                                          tableData?._ads_keywords_of_ads
                                        )
                                      );
                                      setDisplayAt_homepage(
                                        (tableData?.displayed_at).includes(
                                          'homepage'
                                        )
                                      );
                                      setDisplayAt_searchResults(
                                        (tableData?.displayed_at).includes(
                                          'search_results'
                                        )
                                      );
                                      setDisplayAt_relatedTags(
                                        (tableData?.displayed_at).includes(
                                          'related_tags'
                                        )
                                      );
                                      setDisplayAt_userProfiles(
                                        (tableData?.displayed_at).includes(
                                          'user_profiles'
                                        )
                                      );
                                      setAttachmentsValue(
                                        tableData?._ad_attachments
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  color={theme.colors.branding.primary}
                                  icon={'Feather/edit'}
                                  size={28}
                                />
                                {/* EditBudgetButton */}
                                <IconButton
                                  onPress={() => {
                                    try {
                                      setAd_to_edit_budget(tableData);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  color={theme.colors.branding.primary}
                                  icon={'MaterialIcons/attach-money'}
                                  size={28}
                                />
                                {/* StopButton */}
                                <>
                                  {!tableData?.is_active ? null : (
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          setAd_to_toggle(tableData);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={theme.colors.branding.primary}
                                      icon={'Feather/stop-circle'}
                                      size={28}
                                    />
                                  )}
                                </>
                                {/* ActivateButton */}
                                <>
                                  {!showActivateButton(
                                    tableData?.id,
                                    tableData?.is_active
                                  ) ? null : (
                                    <IconButton
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            /* hidden 'Run a Custom Function' action */
                                            setCurrently_toggling_id(
                                              tableData?.id
                                            );
                                            (
                                              await xanoAdminToggleActiveGET.mutateAsync(
                                                { ads_id: tableData?.id }
                                              )
                                            )?.json;
                                            setCurrently_toggling_id(null);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      color={theme.colors.branding.primary}
                                      icon={'Feather/play-circle'}
                                      size={28}
                                    />
                                  )}
                                </>
                                <>
                                  {!(
                                    currently_toggling_id === tableData?.id
                                  ) ? null : (
                                    <ActivityIndicator
                                      animating={true}
                                      hidesWhenStopped={true}
                                      size={'small'}
                                      {...GlobalStyles.ActivityIndicatorStyles(
                                        theme
                                      )['Activity Indicator'].props}
                                      color={theme.colors.branding.primary}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ActivityIndicatorStyles(
                                            theme
                                          )['Activity Indicator'].style,
                                          { marginLeft: 0, marginTop: 0 }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                              </View>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    }}
                    showsVerticalScrollIndicator={true}
                    {...GlobalStyles.TableStyles(theme)['Table'].props}
                    borderWidth={0}
                    drawTopBorder={false}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TableStyles(theme)['Table'].style,
                      dimensions.width
                    )}
                  />
                );
              }}
            </XanoAdminApi.FetchGetMyAdsGET>
          </SimpleStyleScrollView>
        </Surface>
      </View>
      {/* AddNewAdButton */}
      <Pressable
        onPress={() => {
          try {
            setFormMode('add');
            clearForm();
          } catch (err) {
            console.error(err);
          }
        }}
      >
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Surface'].style,
              { borderRadius: 50, bottom: 50, position: 'absolute', right: 50 }
            ),
            dimensions.width
          )}
        >
          <Circle
            {...GlobalStyles.CircleStyles(theme)['Circle'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.CircleStyles(theme)['Circle'].style,
                { height: 70, width: 70 }
              ),
              dimensions.width
            )}
          >
            <Icon
              color={palettes.Brand.Surface}
              name={'Feather/plus'}
              size={50}
            />
          </Circle>
        </Surface>
      </Pressable>
      {/* FormModalView */}
      <>
        {!formMode ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                height: '100%',
                position: 'absolute',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <BlurView
              {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
              intensity={60}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
                  {
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 16,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              {/* ModalContainer */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.Brand.Surface,
                    borderColor: theme.colors.foreground.brand,
                    borderRadius: 16,
                    borderWidth: 1,
                    maxWidth: 800,
                    width: '90%',
                  },
                  dimensions.width
                )}
              >
                {/* ModalHeader */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: theme.colors.foreground.brand,
                      flexDirection: 'row',
                      marginBottom: 8,
                      padding: 16,
                    },
                    dimensions.width
                  )}
                >
                  {/* Edit H3 */}
                  <>
                    {!(formMode === 'edit') ? null : (
                      <H3
                        selectable={false}
                        {...GlobalStyles.H3Styles(theme)['H3'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.H3Styles(theme)['H3'].style,
                            { flex: 1 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Edit Ad'}
                      </H3>
                    )}
                  </>
                  <>
                    {!(formMode === 'add') ? null : (
                      <H3
                        selectable={false}
                        {...GlobalStyles.H3Styles(theme)['H3'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.H3Styles(theme)['H3'].style,
                            { flex: 1 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Add New Ad'}
                      </H3>
                    )}
                  </>
                  <IconButton
                    onPress={() => {
                      try {
                        setFormMode('');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                    icon={'Ionicons/close-circle-outline'}
                  />
                </View>
                {/* ModalBody */}
                <SimpleStyleScrollView
                  bounces={true}
                  horizontal={false}
                  keyboardShouldPersistTaps={'never'}
                  nestedScrollEnabled={false}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  style={StyleSheet.applyWidth(
                    { flex: 1, maxHeight: 700, paddingRight: 16 },
                    dimensions.width
                  )}
                >
                  {/* AdNameField */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['FormFieldView'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['FormFieldView'].style,
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { width: 150 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              marginBottom: 6,
                              marginRight: 6,
                              textAlign: 'right',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Ad Name : '}
                      </Text>
                    </View>
                    {/* AdNameInput */}
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newAdNameInputValue => {
                        try {
                          setAdNameValue(newAdNameInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Text Input']
                        .props}
                      placeholder={'Enter Ad Name'}
                      placeholderTextColor={theme.colors.text.light}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                        dimensions.width
                      )}
                      value={adNameValue}
                    />
                  </View>
                  {/* StartDateField */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['FormFieldView'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['FormFieldView'].style,
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { width: 150 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              marginBottom: 6,
                              marginRight: 6,
                              textAlign: 'right',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Start Date :'}
                      </Text>
                    </View>
                    {/* DatePickerView */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderColor: theme.colors.border.brand,
                          borderRadius: 8,
                          borderWidth: 1,
                          flex: 1,
                          justifyContent: 'center',
                          overflow: 'hidden',
                          paddingBottom: 8,
                          paddingLeft: 15,
                          paddingRight: 8,
                          paddingTop: 8,
                        },
                        dimensions.width
                      )}
                    >
                      <DatePicker
                        autoDismissKeyboard={true}
                        disabled={false}
                        hideLabel={false}
                        inline={false}
                        leftIconMode={'inset'}
                        onDateChange={newDatePickerValue => {
                          const date = newDatePickerValue;
                          try {
                            undefined;
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        borderColorActive={'rgba(0, 0, 0, 0)'}
                        date={startDateValue}
                        label={null}
                        labelColor={'rgba(0, 0, 0, 0)'}
                        minimumDate={new Date()}
                        mode={'datetime'}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.Brand.Surface,
                            borderColor: 'rgba(0, 0, 0, 0)',
                            margin: 0,
                            padding: 0,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        type={'underline'}
                      />
                    </View>
                  </View>
                  {/* BudgetField */}
                  <>
                    {!(formMode === 'add') ? null : (
                      <View
                        {...GlobalStyles.ViewStyles(theme)['FormFieldView']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)['FormFieldView'].style,
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: 150 },
                            dimensions.width
                          )}
                        >
                          {/* Label */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  marginBottom: 6,
                                  marginRight: 6,
                                  textAlign: 'right',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Budget :'}
                          </Text>
                        </View>
                        {/* BudgetSliderView */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flex: 1,
                              flexDirection: 'row',
                              justifyContent: 'center',
                              overflow: 'hidden',
                              paddingBottom: 8,
                              paddingTop: 8,
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <Slider
                              onValueChange={newSliderValue => {
                                try {
                                  setBudgetValue(newSliderValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.SliderStyles(theme)['Slider']
                                .props}
                              maximumValue={divide(
                                Constants['MA_BALANCE'],
                                100
                              )}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.SliderStyles(theme)['Slider']
                                    .style,
                                  { marginLeft: 0, marginRight: 0 }
                                ),
                                dimensions.width
                              )}
                              value={budgetValue}
                            />
                          </View>
                          <NumberInput
                            changeTextDelay={500}
                            onChangeText={newNumberInputValue => {
                              try {
                                setBudgetValue(newNumberInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={'Enter a number...'}
                            webShowOutline={true}
                            {...GlobalStyles.NumberInputStyles(theme)[
                              'Number Input'
                            ].props}
                            disabled={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.NumberInputStyles(theme)[
                                  'Number Input'
                                ].style,
                                { width: 100 }
                              ),
                              dimensions.width
                            )}
                            value={budgetValue}
                          />
                        </View>
                      </View>
                    )}
                  </>
                  {/* ActiveField */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['FormFieldView'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['FormFieldView'].style,
                        {
                          marginBottom: [
                            { minWidth: Breakpoints.Mobile, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 16 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { width: { minWidth: Breakpoints.Tablet, value: 150 } },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              marginBottom: 6,
                              marginRight: 6,
                              textAlign: 'right',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Active :'}
                      </Text>
                    </View>
                    <Checkbox
                      onPress={newCheckboxValue => {
                        const checkboxValue = newCheckboxValue;
                        try {
                          undefined;
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      color={theme.colors.branding.primary}
                      status={isActiveValue}
                      uncheckedColor={theme.colors.text.light}
                    />
                  </View>
                  {/* KeywordsField */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['FormFieldView'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['FormFieldView'].style,
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { width: 150 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              marginBottom: 6,
                              marginRight: 6,
                              textAlign: 'right',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Keywords :'}
                      </Text>
                    </View>
                    <TextInput
                      autoCorrect={true}
                      changeTextDelay={500}
                      multiline={true}
                      numberOfLines={4}
                      onChangeText={newTextAreaValue => {
                        try {
                          setKeywordsValue(newTextAreaValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      textAlignVertical={'top'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Text Area']
                        .props}
                      placeholder={'Enter your keywords with comma'}
                      placeholderTextColor={theme.colors.text.light}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)['Text Area']
                            .style,
                          { width: '100%' }
                        ),
                        dimensions.width
                      )}
                      value={keywordsValue}
                    />
                  </View>
                  {/* DisplayLocationField */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['FormFieldView'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['FormFieldView'].style,
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { width: 150 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              marginBottom: 6,
                              marginRight: 6,
                              textAlign: 'right',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Display At :'}
                      </Text>
                    </View>
                    {/* HomepageCheckbox */}
                    <CheckboxRow
                      onPress={newHomepageCheckboxValue => {
                        const checkboxRowValue = newHomepageCheckboxValue;
                        try {
                          undefined;
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row']
                        .props}
                      direction={'row-reverse'}
                      label={' Homepage'}
                      status={displayAt_homepage}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row']
                          .style,
                        dimensions.width
                      )}
                      uncheckedColor={theme.colors.text.light}
                    />
                    {/* SearchResultsCheckbox */}
                    <CheckboxRow
                      onPress={newSearchResultsCheckboxValue => {
                        try {
                          setDisplayAt_searchResults(
                            newSearchResultsCheckboxValue
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row']
                        .props}
                      direction={'row-reverse'}
                      label={' Search Results'}
                      status={displayAt_searchResults}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row']
                          .style,
                        dimensions.width
                      )}
                      uncheckedColor={theme.colors.text.light}
                    />
                    {/* RelatedTagsCheckbox */}
                    <CheckboxRow
                      onPress={newRelatedTagsCheckboxValue => {
                        try {
                          setDisplayAt_relatedTags(newRelatedTagsCheckboxValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row']
                        .props}
                      direction={'row-reverse'}
                      label={' Related Tags'}
                      status={displayAt_relatedTags}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row']
                          .style,
                        dimensions.width
                      )}
                      uncheckedColor={theme.colors.text.light}
                    />
                    {/* UserProfilesCheckbox */}
                    <CheckboxRow
                      onPress={newUserProfilesCheckboxValue => {
                        try {
                          setDisplayAt_userProfiles(
                            newUserProfilesCheckboxValue
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row']
                        .props}
                      direction={'row-reverse'}
                      label={' User Profiles'}
                      status={displayAt_userProfiles}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row']
                          .style,
                        dimensions.width
                      )}
                      uncheckedColor={theme.colors.text.light}
                    />
                  </View>
                  {/* AttachmentsField */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['FormFieldView'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['FormFieldView'].style,
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { width: 150 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              marginBottom: 6,
                              marginRight: 6,
                              textAlign: 'right',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Attachments :'}
                      </Text>
                    </View>
                    <SimpleStyleFlatList
                      data={[{}].concat(attachmentsValue)}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ??
                        listData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'5NNgVKCs'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* AddNewPressable */}
                            <>
                              {listData?.ad_attachment ? null : (
                                <Pressable
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        const selectedImageResult =
                                          await openImagePickerUtil({
                                            mediaTypes: 'All',
                                            allowsEditing: false,
                                            quality: 0.2,
                                            allowsMultipleSelection: false,
                                            permissionErrorMessage:
                                              'Sorry, we need media library permissions to make this work.',
                                            showAlertOnPermissionError: true,
                                            outputBase64: true,
                                          });

                                        addAttachment(selectedImageResult);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: theme.colors.branding.primary,
                                          fontSize: 16,
                                          marginBottom: 8,
                                          textDecorationLine: 'underline',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Choose File'}
                                  </Text>
                                </Pressable>
                              )}
                            </>
                            {/* AttachmentView */}
                            <>
                              {!listData?.ad_attachment?.url ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderColor: theme.colors.border.brand,
                                      borderRadius: 8,
                                      borderWidth: 1,
                                      flex: 1,
                                      flexDirection: 'row',
                                      height: 100,
                                      margin: 2,
                                      overflow: 'hidden',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <>
                                    {!(
                                      listData?.ad_attachment?.type === 'video'
                                    ) ? null : (
                                      <Icon
                                        name={'MaterialIcons/ondemand-video'}
                                        size={68}
                                        style={StyleSheet.applyWidth(
                                          { height: 100, width: 100 },
                                          dimensions.width
                                        )}
                                      />
                                    )}
                                  </>
                                  <Image
                                    {...GlobalStyles.ImageStyles(theme)['Image']
                                      .props}
                                    resizeMode={'contain'}
                                    source={imageSource(
                                      `${listData?.ad_attachment?.url}`
                                    )}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      dimensions.width
                                    )}
                                  />
                                  {/* LinkView */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App.TransparentWhite,
                                        borderTopLeftRadius: 6,
                                        borderTopRightRadius: 6,
                                        flex: 1,
                                        minHeight: 40,
                                        padding: 4,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <TextInput
                                      autoCorrect={true}
                                      changeTextDelay={500}
                                      multiline={true}
                                      numberOfLines={4}
                                      onChangeText={newTextAreaValue => {
                                        const textInputValue = newTextAreaValue;
                                        try {
                                          updateLinkOfAttachment(
                                            listData?.id,
                                            newTextAreaValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      textAlignVertical={'top'}
                                      webShowOutline={true}
                                      {...GlobalStyles.TextInputStyles(theme)[
                                        'Text Area'
                                      ].props}
                                      defaultValue={listData?.link}
                                      placeholder={'Enter Link'}
                                      placeholderTextColor={
                                        theme.colors.text.light
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextInputStyles(theme)[
                                          'Text Area'
                                        ].style,
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                  {/* DeleteButtonView */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App.TransparentWhite,
                                        borderRadius: 40,
                                        padding: 4,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* DeleteAdBtn */}
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          setAttachmentsValue(
                                            removeFromArr(
                                              attachmentsValue,
                                              listData
                                            )
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      size={32}
                                      icon={'Feather/trash-2'}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                          </>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                      style={StyleSheet.applyWidth(
                        { flex: { minWidth: Breakpoints.BigScreen, value: 1 } },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* DisplayInRandomOrderField */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['FormFieldView'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['FormFieldView'].style,
                        {
                          marginBottom: [
                            { minWidth: Breakpoints.Mobile, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 16 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { width: 150 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              marginBottom: 6,
                              marginRight: 6,
                              textAlign: 'right',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Random Order :'}
                      </Text>
                    </View>
                    <CheckboxRow
                      onPress={newCheckboxRowValue => {
                        try {
                          setDisplayInRandomOrderValue(newCheckboxRowValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row']
                        .props}
                      direction={'row-reverse'}
                      label={'Display attachments in random order'}
                      status={displayInRandomOrderValue}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row']
                          .style,
                        dimensions.width
                      )}
                    />
                  </View>
                </SimpleStyleScrollView>
                {/* ModalFooter */}
                <View
                  style={StyleSheet.applyWidth(
                    { borderRadius: 9, padding: 16 },
                    dimensions.width
                  )}
                >
                  {/* ErrorMessage */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { color: theme.colors.background.danger }
                      ),
                      dimensions.width
                    )}
                  >
                    {errorMessage}{' '}
                  </Text>
                  {/* Buttons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flex: 1,
                        flexDirection: 'row',
                        marginTop: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, marginRight: 6 },
                        dimensions.width
                      )}
                    >
                      {/* CancelButton */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setFormMode('');
                            setAdNameValue('');
                            setStartDateValue(null);
                            setEndDateValue(null);
                            setKeywordsValue('');
                            setAttachmentsValue([]);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['OutlineButton']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ButtonStyles(theme)['OutlineButton']
                            .style,
                          dimensions.width
                        )}
                        title={'Cancel'}
                      />
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, marginLeft: 6 },
                        dimensions.width
                      )}
                    >
                      {/* SaveButton */}
                      <>
                        {!(formMode === 'add') ? null : (
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  if (!validForm()) {
                                    return;
                                  }
                                  setIsCreating(true);
                                  (
                                    await xanoAdminCreateNewAdPOST.mutateAsync({
                                      ad_name: adNameValue,
                                      attachments: convertedAttachmentsValue(),
                                      budget: dollarToCent(budgetValue),
                                      display_at: displayAtArr(),
                                      keywords: splitByComma(keywordsValue),
                                      random_order: displayInRandomOrderValue,
                                      start_date:
                                        dateToTimestamp(startDateValue),
                                    })
                                  )?.json;
                                  setIsCreating(false);
                                  refreshWallet(setGlobalVariableValue);
                                  setFormMode(null);
                                  setBudgetValue(0);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            disabled={isCreating}
                            loading={isCreating}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              dimensions.width
                            )}
                            title={'Save'}
                          />
                        )}
                      </>
                      {/* UpdateButton */}
                      <>
                        {!(formMode === 'edit') ? null : (
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  if (!validForm()) {
                                    return;
                                  }
                                  setIsCreating(true);
                                  (
                                    await xanoAdminUpdateAdPOST.mutateAsync({
                                      ad_name: adNameValue,
                                      ads_id: adIdToEdit,
                                      attachments: convertedAttachmentsValue(),
                                      display_at: displayAtArr(),
                                      end_date: dateToTimestamp(endDateValue),
                                      keywords: splitByComma(keywordsValue),
                                      random_order: displayInRandomOrderValue,
                                      start_date:
                                        dateToTimestamp(startDateValue),
                                    })
                                  )?.json;
                                  setIsCreating(false);
                                  setFormMode(null);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            disabled={isCreating}
                            loading={isCreating}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              dimensions.width
                            )}
                            title={'Update'}
                          />
                        )}
                      </>
                    </View>
                  </View>
                </View>
              </View>
            </BlurView>
          </View>
        )}
      </>
      {/* BudgetModalView */}
      <>
        {!ad_to_edit_budget ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                height: '100%',
                position: 'absolute',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <BlurView
              {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
              intensity={60}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
                  {
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 16,
                  }
                ),
                dimensions.width
              )}
              tint={'dark'}
            >
              {/* ModalContainer */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.Brand.Surface,
                    borderColor: theme.colors.foreground.brand,
                    borderRadius: 16,
                    borderWidth: 1,
                    maxWidth: 800,
                    width: '90%',
                  },
                  dimensions.width
                )}
              >
                {/* ModalHeader */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: theme.colors.foreground.brand,
                      flexDirection: 'row',
                      marginBottom: 8,
                      padding: 16,
                    },
                    dimensions.width
                  )}
                >
                  <H3
                    selectable={false}
                    {...GlobalStyles.H3Styles(theme)['H3'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.H3Styles(theme)['H3'].style,
                        { flex: 1 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Update Ad Budget'}
                  </H3>
                  {/* CloseButton */}
                  <IconButton
                    onPress={() => {
                      try {
                        setAd_to_edit_budget(null);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                    icon={'Ionicons/close-circle-outline'}
                  />
                </View>
                {/* ModalBody */}
                <SimpleStyleScrollView
                  bounces={true}
                  horizontal={false}
                  keyboardShouldPersistTaps={'never'}
                  nestedScrollEnabled={false}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  style={StyleSheet.applyWidth(
                    { flex: 1, maxHeight: 700, paddingRight: 16 },
                    dimensions.width
                  )}
                >
                  <XanoAdminApi.FetchGetBalanceGET
                    refresh={Constants['refresh_balance']}
                  >
                    {({ loading, error, data, refetchGetBalance }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {/* BudgetField */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'stretch',
                                flexDirection: 'column',
                                padding: 16,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'Balance                   : $'}
                                {centToDollar(fetchData?.balance)}
                                {'\nRemaining Budget  : $'}
                                {centToDollar(
                                  ad_to_edit_budget?.remaining_budget
                                )}
                              </Text>
                              <Link
                                accessible={true}
                                onPress={() => {
                                  try {
                                    navigation.navigate('BalanceScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectable={false}
                                {...GlobalStyles.LinkStyles(theme)['Link']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link']
                                      .style,
                                    { marginLeft: 16 }
                                  ),
                                  dimensions.width
                                )}
                                title={'Add Balance'}
                              />
                            </View>
                            {/* BudgetSliderView */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flex: 1,
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  overflow: 'hidden',
                                  paddingBottom: 8,
                                  paddingTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  { flex: 1 },
                                  dimensions.width
                                )}
                              >
                                <Slider
                                  onValueChange={newSliderValue => {
                                    try {
                                      setBudgetValue(newSliderValue);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  {...GlobalStyles.SliderStyles(theme)['Slider']
                                    .props}
                                  maximumValue={fetchData?.balance}
                                  step={100}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.SliderStyles(theme)['Slider']
                                        .style,
                                      { marginLeft: 0, marginRight: 0 }
                                    ),
                                    dimensions.width
                                  )}
                                  value={budgetValue}
                                />
                              </View>

                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'$ '}
                                {centToDollar(budgetValue)}
                              </Text>
                            </View>
                            {/* NewBalance */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {'New Budget for the ad will be $'}
                              {centToDollar(
                                sum(
                                  ad_to_edit_budget?.remaining_budget,
                                  budgetValue
                                )
                              )}
                            </Text>
                          </View>
                        </>
                      );
                    }}
                  </XanoAdminApi.FetchGetBalanceGET>
                </SimpleStyleScrollView>
                {/* ModalFooter */}
                <View
                  style={StyleSheet.applyWidth(
                    { borderRadius: 9, padding: 16 },
                    dimensions.width
                  )}
                >
                  {/* ErrorMessage */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { color: theme.colors.background.danger }
                      ),
                      dimensions.width
                    )}
                  >
                    {errorMessage}{' '}
                  </Text>
                  {/* Buttons */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flex: 1, flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, marginRight: 6 },
                        dimensions.width
                      )}
                    >
                      {/* CancelButton */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setFormMode('');
                            setAdNameValue('');
                            setStartDateValue(null);
                            setEndDateValue(null);
                            setKeywordsValue('');
                            setAttachmentsValue([]);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['OutlineButton']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ButtonStyles(theme)['OutlineButton']
                            .style,
                          dimensions.width
                        )}
                        title={'Cancel'}
                      />
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, marginLeft: 6 },
                        dimensions.width
                      )}
                    >
                      {/* UpdateButton */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              if (!(budgetValue > 0)) {
                                return;
                              }
                              setIsCreating(true);
                              (
                                await xanoAdminUpdateAdBudgetPATCH.mutateAsync({
                                  ad_id: ad_to_edit_budget?.id,
                                  balance: budgetValue,
                                })
                              )?.json;
                              setIsCreating(false);
                              refreshWallet(setGlobalVariableValue);
                              setAd_to_edit_budget(null);
                              setBudgetValue(0);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                        disabled={isCreating}
                        loading={isCreating}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          dimensions.width
                        )}
                        title={'Yes, Update!'}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </BlurView>
          </View>
        )}
      </>
      {/* AttachmentPreviewModal */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        presentationStyle={'fullScreen'}
        transparent={true}
        visible={attachment_to_preview}
      >
        <BlurView
          tint={'default'}
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          intensity={20}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
              { alignItems: 'center', justifyContent: 'center', padding: 16 }
            ),
            dimensions.width
          )}
        >
          {/* ModalContainer */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.Brand.Surface,
                borderColor: theme.colors.foreground.brand,
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 800,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* ModalHeader */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: theme.colors.foreground.brand,
                  flexDirection: 'row',
                  marginBottom: 8,
                  padding: 16,
                },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { flex: 1, fontFamily: 'SpaceGrotesk_600SemiBold' }
                  ),
                  dimensions.width
                )}
              >
                {'Preview Attachment'}
              </Text>
              <IconButton
                onPress={() => {
                  try {
                    setAttachment_to_preview(null);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'AntDesign/closecircleo'}
              />
            </View>
            {/* ModalBody */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexGrow: 1, flexShrink: 0, padding: 16 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { marginBottom: 8, marginLeft: 16 }
                  ),
                  dimensions.width
                )}
              >
                {'Attachment Asset :'}
              </Text>
              <>
                {!(
                  attachment_to_preview?.ad_attachment?.type === 'image'
                ) ? null : (
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={imageSource(
                      `${attachment_to_preview?.ad_attachment?.url}`
                    )}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: [
                            { minWidth: Breakpoints.Mobile, value: '100%' },
                            {
                              minWidth: Breakpoints.Desktop,
                              value:
                                attachment_to_preview?.ad_attachment.meta
                                  .height,
                            },
                          ],
                          maxHeight: 768,
                          maxWidth: 768,
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '100%' },
                            {
                              minWidth: Breakpoints.Desktop,
                              value:
                                attachment_to_preview?.ad_attachment.meta.width,
                            },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              <>
                {!(
                  attachment_to_preview?.ad_attachment?.type === 'video'
                ) ? null : (
                  <VideoPlayer
                    isLooping={false}
                    posterResizeMode={'cover'}
                    rate={1}
                    resizeMode={'cover'}
                    shouldPlay={false}
                    useNativeControls={true}
                    usePoster={false}
                    volume={0.5}
                    {...GlobalStyles.VideoPlayerStyles(theme)['Video'].props}
                    isMuted={true}
                    source={imageSource(
                      `${attachment_to_preview?.ad_attachment?.url}`
                    )}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.VideoPlayerStyles(theme)['Video'].style,
                        {
                          height: [
                            { minWidth: Breakpoints.Mobile, value: '100%' },
                            {
                              minWidth: Breakpoints.Desktop,
                              value:
                                attachment_to_preview?.ad_attachment.meta.video
                                  .postHeight,
                            },
                          ],
                          maxHeight: 768,
                          maxWidth: 768,
                          minHeight: 250,
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '100%' },
                            {
                              minWidth: Breakpoints.Desktop,
                              value:
                                attachment_to_preview?.ad_attachment.meta.video
                                  .postWidth,
                            },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Label 2 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { marginBottom: 8 }
                  ),
                  dimensions.width
                )}
              >
                {'Attachment Link :'}
              </Text>
              {/* Link */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {attachment_to_preview?.link}
              </Text>
            </View>
          </View>
        </BlurView>
      </Modal>
      {/* DeleteModal */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        presentationStyle={'fullScreen'}
        transparent={true}
        visible={ad_to_toggle}
      >
        <BlurView
          tint={'default'}
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          intensity={20}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
              { alignItems: 'center', justifyContent: 'center', padding: 16 }
            ),
            dimensions.width
          )}
        >
          {/* ModalContainer */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: palettes.Brand.Surface,
                  },
                  {
                    minWidth: Breakpoints.Desktop,
                    value: palettes.Brand.Surface,
                  },
                ],
                borderColor: theme.colors.foreground.brand,
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* ModalHeader */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: theme.colors.foreground.brand,
                  flexDirection: 'row',
                  marginBottom: 8,
                  padding: 16,
                },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { flex: 1, fontFamily: 'SpaceGrotesk_600SemiBold' }
                  ),
                  dimensions.width
                )}
              >
                {'Are You Sure ?'}
              </Text>
              <IconButton
                onPress={() => {
                  try {
                    setAd_to_toggle(null);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'AntDesign/closecircleo'}
              />
            </View>
            {/* ModalBody */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexGrow: 1, flexShrink: 0, padding: 16 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { marginBottom: 8, marginLeft: 16 }
                  ),
                  dimensions.width
                )}
              >
                {'You are about to stop the "'}
                {ad_to_toggle?.ad_name}
                {'" Ad. Are you sure that you want to continue?'}
              </Text>
            </View>
            {/* ModalFooter */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderRadius: 9,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                {/* CancelButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setAd_to_toggle(null);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['OutlineButton'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ButtonStyles(theme)['OutlineButton'].style,
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                {/* StopButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        undefined;
                        (
                          await xanoAdminToggleActiveGET.mutateAsync({
                            ads_id: ad_to_toggle?.id,
                          })
                        )?.json;
                        undefined;
                        setAd_to_toggle(null);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={currently_toggling_id}
                  loading={currently_toggling_id}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { backgroundColor: theme.colors.background.danger }
                    ),
                    dimensions.width
                  )}
                  title={'Yes, Stop'}
                />
              </View>
            </View>
          </View>
        </BlurView>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(AdsManagementScreen);
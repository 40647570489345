import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const createPaymentIntentPOST = async (
  Constants,
  { amount, description },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:dYtFDgIu/create_payment_intent`;
  const options = {
    body: JSON.stringify({ amount: amount, description: description }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreatePaymentIntentPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['stripeServiceCreatePaymentIntentPOST', args],
    () => createPaymentIntentPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'stripeServiceCreatePaymentIntentPOSTS',
        ]),
    }
  );
};

export const FetchCreatePaymentIntentPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  amount,
  description,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCreatePaymentIntentPOST(
    { amount, description },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreatePaymentIntent: refetch,
  });
};

import React from 'react';
import {
  Button,
  IconButton,
  Pressable,
  Table,
  TableCell,
  TableRow,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import { ActivityIndicator, Image, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAdminApi from '../apis/XanoAdminApi.js';
import FacetPickerBlock from '../components/FacetPickerBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Lib from '../custom-files/Lib';
import getTableRowColor from '../global-functions/getTableRowColor';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { onUpdate: () => {} };

const WhatsHotTableBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [attachmentValue, setAttachmentValue] = React.useState({});
  const [currently_deleting_id, setCurrently_deleting_id] = React.useState(0);
  const [currently_editing_id, setCurrently_editing_id] = React.useState(0);
  const [facetValue, setFacetValue] = React.useState([]);
  const [is_moving, setIs_moving] = React.useState(false);
  const [is_saving, setIs_saving] = React.useState(false);
  const [item_to_delete, setItem_to_delete] = React.useState(null);
  const [orderValue, setOrderValue] = React.useState(0);
  const [queryValue, setQueryValue] = React.useState('');
  const [tempRow, setTempRow] = React.useState(null);
  const [titleValue, setTitleValue] = React.useState('');
  const [urlValue, setUrlValue] = React.useState('');
  const updateFacet = (facetType, value) => {
    setFacetValue([`${facetType}:${value}`]);
  };

  const ShowMessage = (header, message, type) => {
    if (!['success', 'error', 'info'].includes(type)) return;
    if (!message) return;

    Lib.Toast.show({
      type,
      text1: header || 'Montage',
      text2: message,
    });
  };

  const validateForm = () => {
    if (titleValue.length < 1) {
      ShowMessage('Error', 'Title is required', 'error');
      return false;
    }

    if (urlValue.length < 1 && facetValue?.[0] === '') {
      ShowMessage('Error', 'Either URL or Facet is required', 'error');
      return false;
    }

    if (
      urlValue.length > 0 &&
      /^(http|https):\/\/[^ "]+$/.test(urlValue) == false
    ) {
      ShowMessage(
        'Error',
        'Please enter link in https://www.example.com format',
        'error'
      );
      return false;
    }

    if (!attachmentValue) {
      ShowMessage('Error', 'Image is required', 'error');
      return false;
    }

    return true;
  };
  const xanoAdminUpdateWhatsHotPUT = XanoAdminApi.useUpdateWhatsHotPUT();
  const xanoAdminAddWhatsHotPOST = XanoAdminApi.useAddWhatsHotPOST();
  const xanoAdminDeleteWhatshotDELETE = XanoAdminApi.useDeleteWhatshotDELETE();

  return (
    <View
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.Brand.Surface },
        dimensions.width
      )}
    >
      <XanoAdminApi.FetchGetDiscoverWhatshotGET
        handlers={{
          onData: fetchData => {
            try {
              console.log(fetchData);
            } catch (err) {
              console.error(err);
            }
          },
        }}
      >
        {({ loading, error, data, refetchGetDiscoverWhatshot }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              <Table
                borderColor={theme.colors.border.brand}
                borderStyle={'solid'}
                cellHorizontalPadding={10}
                cellVerticalPadding={10}
                data={fetchData}
                drawBottomBorder={false}
                drawEndBorder={false}
                drawStartBorder={false}
                keyExtractor={(tableData, index) =>
                  tableData?.id ??
                  tableData?.uuid ??
                  index?.toString() ??
                  JSON.stringify(tableData)
                }
                listKey={'nBhaqoE6'}
                renderItem={({ item, index }) => {
                  const tableData = item;
                  return (
                    <>
                      {/* Header */}
                      <TableRow
                        drawEndBorder={false}
                        drawTopBorder={false}
                        borderStyle={'solid'}
                        borderWidth={0}
                        drawBottomBorder={false}
                        drawStartBorder={false}
                        isTableHeader={true}
                      >
                        {/* OrderCell */}
                        <TableCell
                          drawBottomBorder={false}
                          drawStartBorder={false}
                          drawTopBorder={false}
                          {...GlobalStyles.TableCellStyles(theme)[
                            'TableHeaderCell'
                          ].props}
                          borderStyle={'solid'}
                          borderWidth={0}
                          drawEndBorder={false}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              { flex: 0, minWidth: 80, width: 80 }
                            ),
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'TableHeaderCellText'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Order'}
                          </Text>
                        </TableCell>
                        {/* TitleCell */}
                        <TableCell
                          drawBottomBorder={false}
                          drawStartBorder={false}
                          drawTopBorder={false}
                          {...GlobalStyles.TableCellStyles(theme)[
                            'TableHeaderCell'
                          ].props}
                          borderStyle={'solid'}
                          drawEndBorder={false}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              { minWidth: 0, width: '20%' }
                            ),
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'TableHeaderCellText'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Title\n'}
                          </Text>
                        </TableCell>
                        {/* UrlCell */}
                        <TableCell
                          drawBottomBorder={false}
                          drawStartBorder={false}
                          drawTopBorder={false}
                          {...GlobalStyles.TableCellStyles(theme)[
                            'TableHeaderCell'
                          ].props}
                          borderStyle={'solid'}
                          drawEndBorder={false}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              { minWidth: 0, width: '20%' }
                            ),
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'TableHeaderCellText'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'URL'}
                          </Text>
                        </TableCell>
                        {/* FacetCell */}
                        <TableCell
                          drawBottomBorder={false}
                          drawStartBorder={false}
                          drawTopBorder={false}
                          {...GlobalStyles.TableCellStyles(theme)[
                            'TableHeaderCell'
                          ].props}
                          borderStyle={'solid'}
                          drawEndBorder={false}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              { minWidth: 0, width: '20%' }
                            ),
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'TableHeaderCellText'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Algolia Filter'}
                          </Text>
                        </TableCell>
                        {/* QueryCell */}
                        <TableCell
                          drawBottomBorder={false}
                          drawStartBorder={false}
                          drawTopBorder={false}
                          {...GlobalStyles.TableCellStyles(theme)[
                            'TableHeaderCell'
                          ].props}
                          borderStyle={'solid'}
                          drawEndBorder={false}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              { minWidth: 0, width: '20%' }
                            ),
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'TableHeaderCellText'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Query'}
                          </Text>
                        </TableCell>
                        {/* ImageCell */}
                        <TableCell
                          drawBottomBorder={false}
                          drawStartBorder={false}
                          drawTopBorder={false}
                          {...GlobalStyles.TableCellStyles(theme)[
                            'TableHeaderCell'
                          ].props}
                          borderStyle={'solid'}
                          drawEndBorder={false}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              { minWidth: 0, width: '20%' }
                            ),
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'TableHeaderCellText'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Image'}
                          </Text>
                        </TableCell>
                        {/* ActionsCell */}
                        <TableCell
                          drawBottomBorder={false}
                          drawStartBorder={false}
                          drawTopBorder={false}
                          {...GlobalStyles.TableCellStyles(theme)[
                            'TableHeaderCell'
                          ].props}
                          borderStyle={'solid'}
                          drawEndBorder={false}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TableCellStyles(theme)[
                                'TableHeaderCell'
                              ].style,
                              { flex: 0, minWidth: 80, width: 80 }
                            ),
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'TableHeaderCellText'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'TableHeaderCellText'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Actions'}
                          </Text>
                        </TableCell>
                      </TableRow>
                      {/* BodyRow */}
                      <>
                        {!tableData?.id ? null : (
                          <TableRow
                            drawEndBorder={false}
                            drawTopBorder={false}
                            isTableHeader={false}
                            borderStyle={'dashed'}
                            borderWidth={0}
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            style={StyleSheet.applyWidth(
                              { backgroundColor: getTableRowColor(index) },
                              dimensions.width
                            )}
                          >
                            {/* OrderCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { flex: 0, minWidth: 80, width: 80 }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* UpButton */}
                              <IconButton
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      setIs_moving(true);
                                      (
                                        await XanoAdminApi.moveDiscoverWhatshotGET(
                                          Constants,
                                          {
                                            direction: 'up',
                                            discover_whatshot_id: tableData?.id,
                                          }
                                        )
                                      )?.json;
                                      setIs_moving(false);
                                      await refetchGetDiscoverWhatshot();
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                color={theme.colors.branding.primary}
                                disabled={is_moving}
                                icon={'Feather/arrow-up'}
                                size={28}
                              />
                              <View
                                style={StyleSheet.applyWidth(
                                  { alignItems: 'center', width: 15 },
                                  dimensions.width
                                )}
                              >
                                <>
                                  {!is_moving ? null : (
                                    <ActivityIndicator
                                      animating={true}
                                      hidesWhenStopped={true}
                                      size={'small'}
                                      {...GlobalStyles.ActivityIndicatorStyles(
                                        theme
                                      )['Activity Indicator'].props}
                                      color={theme.colors.branding.primary}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ActivityIndicatorStyles(
                                            theme
                                          )['Activity Indicator'].style,
                                          { height: 16, width: 16 }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                <>
                                  {is_moving ? null : (
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {tableData?.order}
                                    </Text>
                                  )}
                                </>
                              </View>
                              {/* DownButton */}
                              <IconButton
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      setIs_moving(true);
                                      (
                                        await XanoAdminApi.moveDiscoverWhatshotGET(
                                          Constants,
                                          {
                                            direction: 'down',
                                            discover_whatshot_id: tableData?.id,
                                          }
                                        )
                                      )?.json;
                                      setIs_moving(false);
                                      await refetchGetDiscoverWhatshot();
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                color={theme.colors.branding.primary}
                                disabled={is_moving}
                                icon={'Feather/arrow-down'}
                                size={28}
                              />
                            </TableCell>
                            {/* TitleCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { minWidth: 0, width: '20%' }
                                ),
                                dimensions.width
                              )}
                            >
                              <>
                                {!(
                                  currently_editing_id !== tableData?.id
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {tableData?.title}
                                  </Text>
                                )}
                              </>
                              {/* TitleInput */}
                              <>
                                {!(
                                  currently_editing_id === tableData?.id
                                ) ? null : (
                                  <TextInput
                                    autoCapitalize={'none'}
                                    autoCorrect={true}
                                    changeTextDelay={500}
                                    onChangeText={newTitleInputValue => {
                                      try {
                                        setTitleValue(newTitleInputValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    webShowOutline={true}
                                    {...GlobalStyles.TextInputStyles(theme)[
                                      'Text Input'
                                    ].props}
                                    placeholder={'Enter a Title Value'}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextInputStyles(theme)[
                                          'Text Input'
                                        ].style,
                                        {
                                          backgroundColor:
                                            palettes.Brand.Surface,
                                          flex: 1,
                                          height: 35,
                                          paddingBottom: 6,
                                          paddingLeft: 6,
                                          paddingRight: 6,
                                          paddingTop: 6,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    value={titleValue}
                                  />
                                )}
                              </>
                            </TableCell>
                            {/* UrlCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { minWidth: 0, width: '20%' }
                                ),
                                dimensions.width
                              )}
                            >
                              <>
                                {!(
                                  currently_editing_id !== tableData?.id
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {tableData?.url}
                                  </Text>
                                )}
                              </>
                              <>
                                {!(
                                  currently_editing_id === tableData?.id
                                ) ? null : (
                                  <TextInput
                                    autoCapitalize={'none'}
                                    autoCorrect={true}
                                    changeTextDelay={500}
                                    onChangeText={newTextInputValue => {
                                      try {
                                        setUrlValue(newTextInputValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    webShowOutline={true}
                                    {...GlobalStyles.TextInputStyles(theme)[
                                      'Text Input'
                                    ].props}
                                    placeholder={'Enter an Url'}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextInputStyles(theme)[
                                          'Text Input'
                                        ].style,
                                        {
                                          backgroundColor:
                                            palettes.Brand.Surface,
                                          flex: 1,
                                          height: 35,
                                          paddingBottom: 6,
                                          paddingLeft: 6,
                                          paddingRight: 6,
                                          paddingTop: 6,
                                          textTransform: {
                                            minWidth: Breakpoints.Desktop,
                                            value: null,
                                          },
                                          width: {
                                            minWidth: Breakpoints.Desktop,
                                            value: null,
                                          },
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    value={urlValue}
                                  />
                                )}
                              </>
                            </TableCell>
                            {/* FacetCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'solid'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    minWidth: 0,
                                    width: '20%',
                                    zIndex: {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 0,
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <FacetPickerBlock
                                onSave={list => {
                                  try {
                                    setFacetValue(list);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                defaultValue={tableData?.facet}
                                enableEdit={
                                  currently_editing_id === tableData?.id
                                }
                                initialValue={tableData?.facet}
                              />
                            </TableCell>
                            {/* QueryCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { minWidth: 0, width: '20%' }
                                ),
                                dimensions.width
                              )}
                            >
                              <>
                                {!(
                                  currently_editing_id !== tableData?.id
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {tableData?.query}
                                  </Text>
                                )}
                              </>
                              <>
                                {!(
                                  currently_editing_id === tableData?.id
                                ) ? null : (
                                  <TextInput
                                    autoCapitalize={'none'}
                                    autoCorrect={true}
                                    changeTextDelay={500}
                                    onChangeText={newTextInputValue => {
                                      try {
                                        setQueryValue(newTextInputValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    webShowOutline={true}
                                    {...GlobalStyles.TextInputStyles(theme)[
                                      'Text Input'
                                    ].props}
                                    placeholder={'Enter a query'}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextInputStyles(theme)[
                                          'Text Input'
                                        ].style,
                                        {
                                          backgroundColor:
                                            palettes.Brand.Surface,
                                          flex: 1,
                                          height: 35,
                                          paddingBottom: 6,
                                          paddingLeft: 6,
                                          paddingRight: 6,
                                          paddingTop: 6,
                                          textTransform: {
                                            minWidth: Breakpoints.Desktop,
                                            value: null,
                                          },
                                          width: {
                                            minWidth: Breakpoints.Desktop,
                                            value: null,
                                          },
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    value={queryValue}
                                  />
                                )}
                              </>
                            </TableCell>
                            {/* AttachmentsCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    maxHeight: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 90,
                                    },
                                    minWidth: 0,
                                    width: '20%',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <>
                                {!(
                                  currently_editing_id !== tableData?.id
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 'center',
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 'center',
                                          },
                                        ],
                                        borderColor: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: theme.colors.text.light,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: theme.colors.text.light,
                                          },
                                        ],
                                        borderRadius: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 6,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 6,
                                          },
                                        ],
                                        borderWidth: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 1,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 1,
                                          },
                                        ],
                                        justifyContent: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 'center',
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 'center',
                                          },
                                        ],
                                        marginRight: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 4,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 4,
                                          },
                                        ],
                                        overflow: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 'hidden',
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 'hidden',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Image
                                      resizeMode={'cover'}
                                      {...GlobalStyles.ImageStyles(theme)[
                                        'Image'
                                      ].props}
                                      source={{
                                        uri: `${tableData?.image?.url}`,
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ].style,
                                          { height: 50, width: 50 }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                )}
                              </>
                              <>
                                {!(
                                  currently_editing_id === tableData?.id
                                ) ? null : (
                                  <Pressable
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          const selectedImage =
                                            await openImagePickerUtil({
                                              mediaTypes: 'Images',
                                              allowsEditing: false,
                                              quality: 0.2,
                                              allowsMultipleSelection: false,
                                              permissionErrorMessage:
                                                'Sorry, we need media library permissions to make this work.',
                                              showAlertOnPermissionError: true,
                                              outputBase64: true,
                                            });

                                          setAttachmentValue(selectedImage);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          borderColor: theme.colors.text.light,
                                          borderRadius: 6,
                                          borderWidth: 1,
                                          marginRight: 4,
                                          overflow: 'hidden',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Image 2 */}
                                      <Image
                                        resizeMode={'cover'}
                                        {...GlobalStyles.ImageStyles(theme)[
                                          'Image'
                                        ].props}
                                        source={{ uri: `${attachmentValue}` }}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.ImageStyles(theme)[
                                              'Image'
                                            ].style,
                                            { height: 50, width: 50 }
                                          ),
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                  </Pressable>
                                )}
                              </>
                            </TableCell>
                            {/* ActionsCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { flex: 0, minWidth: 80, width: 80 }
                                ),
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'center',
                                    },
                                    flexDirection: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'row',
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* EditButton */}
                                <>
                                  {!(
                                    currently_editing_id !== tableData?.id
                                  ) ? null : (
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          setCurrently_editing_id(
                                            tableData?.id
                                          );
                                          setOrderValue(tableData?.order);
                                          setTitleValue(tableData?.title);
                                          setUrlValue(tableData?.url);
                                          setQueryValue(tableData?.query);
                                          setFacetValue(tableData?.facet);
                                          setAttachmentValue(
                                            tableData?.image?.url
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={theme.colors.branding.primary}
                                      icon={'Feather/edit'}
                                      size={28}
                                    />
                                  )}
                                </>
                                {/* DeleteButton */}
                                <>
                                  {!(
                                    currently_editing_id !== tableData?.id
                                  ) ? null : (
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          setItem_to_delete(tableData);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={theme.colors.branding.primary}
                                      icon={'Feather/trash-2'}
                                      size={28}
                                    />
                                  )}
                                </>
                                {/* SaveButton */}
                                <>
                                  {!(
                                    currently_editing_id === tableData?.id
                                  ) ? null : (
                                    <IconButton
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            if (!validateForm()) {
                                              return;
                                            }
                                            setIs_saving(true);
                                            (
                                              await xanoAdminUpdateWhatsHotPUT.mutateAsync(
                                                {
                                                  discover_whatshot_id:
                                                    tableData?.id,
                                                  facetVal: facetValue,
                                                  imageData: attachmentValue,
                                                  orderVal: orderValue,
                                                  query: queryValue,
                                                  title: titleValue,
                                                  urlVal: urlValue,
                                                }
                                              )
                                            )?.json;
                                            setIs_saving(false);
                                            setTempRow(null);
                                            setCurrently_editing_id(null);
                                            await refetchGetDiscoverWhatshot();
                                            props.onUpdate?.();
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      color={theme.colors.branding.primary}
                                      disabled={is_saving}
                                      icon={'Feather/save'}
                                      size={28}
                                    />
                                  )}
                                </>
                                {/* CancelButton */}
                                <>
                                  {!(
                                    currently_editing_id === tableData?.id
                                  ) ? null : (
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          if (tableData?.id === -1) {
                                            setTempRow(null);
                                          } else {
                                          }

                                          setCurrently_editing_id(null);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={theme.colors.branding.primary}
                                      icon={'AntDesign/closecircleo'}
                                      size={28}
                                    />
                                  )}
                                </>
                                <>
                                  {!(
                                    currently_editing_id === tableData?.id &&
                                    is_saving
                                  ) ? null : (
                                    <ActivityIndicator
                                      animating={true}
                                      hidesWhenStopped={true}
                                      size={'small'}
                                      {...GlobalStyles.ActivityIndicatorStyles(
                                        theme
                                      )['Activity Indicator'].props}
                                      color={theme.colors.branding.primary}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ActivityIndicatorStyles(
                                            theme
                                          )['Activity Indicator'].style,
                                          {
                                            marginLeft: {
                                              minWidth: Breakpoints.Desktop,
                                              value: 0,
                                            },
                                            marginTop: {
                                              minWidth: Breakpoints.Desktop,
                                              value: 0,
                                            },
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                              </View>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                      {/* AddNewRow */}
                      <>
                        {!(
                          tempRow?.id && index === fetchData?.length - 1
                        ) ? null : (
                          <TableRow
                            drawEndBorder={false}
                            drawTopBorder={false}
                            isTableHeader={false}
                            borderStyle={'dashed'}
                            borderWidth={0}
                            drawBottomBorder={false}
                            drawStartBorder={false}
                            style={StyleSheet.applyWidth(
                              { backgroundColor: getTableRowColor(index) },
                              dimensions.width
                            )}
                          >
                            {/* OrderCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    flex: 0,
                                    justifyContent: 'center',
                                    minWidth: 80,
                                    width: 80,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <>
                                {is_moving ? null : (
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {orderValue}
                                  </Text>
                                )}
                              </>
                            </TableCell>
                            {/* TitleCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawEndBorder={true}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { minWidth: 0, width: '20%' }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* TitleInput */}
                              <TextInput
                                autoCapitalize={'none'}
                                autoCorrect={true}
                                changeTextDelay={500}
                                onChangeText={newTitleInputValue => {
                                  try {
                                    setTitleValue(newTitleInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                webShowOutline={true}
                                {...GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ].props}
                                placeholder={'Enter a Title Value'}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextInputStyles(theme)[
                                      'Text Input'
                                    ].style,
                                    {
                                      backgroundColor: palettes.Brand.Surface,
                                      flex: 1,
                                      height: 35,
                                      paddingBottom: 6,
                                      paddingLeft: 6,
                                      paddingRight: 6,
                                      paddingTop: 6,
                                    }
                                  ),
                                  dimensions.width
                                )}
                                value={titleValue}
                              />
                            </TableCell>
                            {/* UrlCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { minWidth: 0, width: '20%' }
                                ),
                                dimensions.width
                              )}
                            >
                              <TextInput
                                autoCapitalize={'none'}
                                autoCorrect={true}
                                changeTextDelay={500}
                                onChangeText={newTextInputValue => {
                                  try {
                                    setUrlValue(newTextInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                webShowOutline={true}
                                {...GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ].props}
                                placeholder={'Enter an Url'}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextInputStyles(theme)[
                                      'Text Input'
                                    ].style,
                                    {
                                      backgroundColor: palettes.Brand.Surface,
                                      flex: 1,
                                      height: 35,
                                      paddingBottom: 6,
                                      paddingLeft: 6,
                                      paddingRight: 6,
                                      paddingTop: 6,
                                      textTransform: {
                                        minWidth: Breakpoints.Desktop,
                                        value: null,
                                      },
                                      width: {
                                        minWidth: Breakpoints.Desktop,
                                        value: null,
                                      },
                                    }
                                  ),
                                  dimensions.width
                                )}
                                value={urlValue}
                              />
                            </TableCell>
                            {/* FacetCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'solid'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    minWidth: 0,
                                    width: '20%',
                                    zIndex: {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 0,
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <FacetPickerBlock
                                onSave={list => {
                                  try {
                                    setFacetValue(list);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                defaultValue={facetValue}
                                enableEdit={true}
                                initialValue={facetValue}
                              />
                            </TableCell>
                            {/* QueryCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { minWidth: 0, width: '20%' }
                                ),
                                dimensions.width
                              )}
                            >
                              <TextInput
                                autoCapitalize={'none'}
                                autoCorrect={true}
                                changeTextDelay={500}
                                onChangeText={newTextInputValue => {
                                  try {
                                    setQueryValue(newTextInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                webShowOutline={true}
                                {...GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ].props}
                                placeholder={'Enter an Url'}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextInputStyles(theme)[
                                      'Text Input'
                                    ].style,
                                    {
                                      backgroundColor: palettes.Brand.Surface,
                                      flex: 1,
                                      height: 35,
                                      paddingBottom: 6,
                                      paddingLeft: 6,
                                      paddingRight: 6,
                                      paddingTop: 6,
                                      textTransform: {
                                        minWidth: Breakpoints.Desktop,
                                        value: null,
                                      },
                                      width: {
                                        minWidth: Breakpoints.Desktop,
                                        value: null,
                                      },
                                    }
                                  ),
                                  dimensions.width
                                )}
                                value={queryValue}
                              />
                            </TableCell>
                            {/* AttachmentsCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    maxHeight: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 90,
                                    },
                                    minWidth: 0,
                                    width: '20%',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <Pressable
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      const selectedImage =
                                        await openImagePickerUtil({
                                          mediaTypes: 'Images',
                                          allowsEditing: false,
                                          quality: 0.2,
                                          allowsMultipleSelection: false,
                                          permissionErrorMessage:
                                            'Sorry, we need media library permissions to make this work.',
                                          showAlertOnPermissionError: true,
                                          outputBase64: true,
                                        });

                                      setAttachmentValue(selectedImage);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderColor: theme.colors.text.light,
                                      borderRadius: 6,
                                      borderWidth: 1,
                                      justifyContent: 'center',
                                      marginRight: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 4,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 4,
                                        },
                                      ],
                                      overflow: 'hidden',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Image 2 */}
                                  <Image
                                    resizeMode={'cover'}
                                    {...GlobalStyles.ImageStyles(theme)['Image']
                                      .props}
                                    source={{ uri: `${attachmentValue}` }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)['Image']
                                          .style,
                                        { height: 50, width: 50 }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                </View>
                              </Pressable>
                            </TableCell>
                            {/* ActionsCell */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderStyle={'dashed'}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { flex: 0, minWidth: 80, width: 80 }
                                ),
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'center',
                                    },
                                    flexDirection: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 'row',
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* SaveButton */}
                                <IconButton
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        if (!validateForm()) {
                                          return;
                                        }
                                        setIs_saving(true);
                                        (
                                          await xanoAdminAddWhatsHotPOST.mutateAsync(
                                            {
                                              facetVal: facetValue,
                                              imageData: attachmentValue,
                                              orderVal: orderValue,
                                              query: queryValue,
                                              title: titleValue,
                                              urlVal: urlValue,
                                            }
                                          )
                                        )?.json;
                                        setIs_saving(false);
                                        setTempRow(null);
                                        setCurrently_editing_id(null);
                                        await refetchGetDiscoverWhatshot();
                                        props.onUpdate?.();
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  color={theme.colors.branding.primary}
                                  disabled={is_saving}
                                  icon={'Feather/save'}
                                  size={28}
                                />
                                {/* CancelButton */}
                                <IconButton
                                  onPress={() => {
                                    try {
                                      setTempRow(null);
                                      setCurrently_editing_id(null);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  color={theme.colors.branding.primary}
                                  icon={'AntDesign/closecircleo'}
                                  size={28}
                                />
                                <>
                                  {!(
                                    currently_editing_id === tableData?.id &&
                                    is_saving
                                  ) ? null : (
                                    <ActivityIndicator
                                      animating={true}
                                      hidesWhenStopped={true}
                                      size={'small'}
                                      {...GlobalStyles.ActivityIndicatorStyles(
                                        theme
                                      )['Activity Indicator'].props}
                                      color={theme.colors.branding.primary}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ActivityIndicatorStyles(
                                            theme
                                          )['Activity Indicator'].style,
                                          {
                                            marginLeft: {
                                              minWidth: Breakpoints.Desktop,
                                              value: 0,
                                            },
                                            marginTop: {
                                              minWidth: Breakpoints.Desktop,
                                              value: 0,
                                            },
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                              </View>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    </>
                  );
                }}
                showsVerticalScrollIndicator={true}
                {...GlobalStyles.TableStyles(theme)['Table'].props}
                borderWidth={0}
                drawTopBorder={false}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TableStyles(theme)['Table'].style,
                  dimensions.width
                )}
              />
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'flex-end', margin: 12 },
                  dimensions.width
                )}
              >
                {/* AddNewButton */}
                <>
                  {currently_editing_id ? null : (
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setTempRow({
                            id: -1,
                            url: '',
                            facet: [],
                            title: '',
                            imageData: null,
                          });
                          setCurrently_editing_id(-1);
                          setOrderValue(fetchData?.length + 1);
                          setTitleValue('');
                          setUrlValue('');
                          setQueryValue('');
                          setFacetValue([]);
                          setAttachmentValue(null);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      disabled={tempRow?.id}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          { width: 180 }
                        ),
                        dimensions.width
                      )}
                      title={'Add New Card'}
                    />
                  )}
                </>
                {/* UpdateButton */}
                <>
                  {!currently_editing_id ? null : (
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            if (!validateForm()) {
                              return;
                            }
                            setIs_saving(true);
                            if (currently_editing_id === -1) {
                              (
                                await xanoAdminAddWhatsHotPOST.mutateAsync({
                                  facetVal: facetValue,
                                  imageData: attachmentValue,
                                  orderVal: orderValue,
                                  query: queryValue,
                                  title: titleValue,
                                  urlVal: urlValue,
                                })
                              )?.json;
                              setTempRow(null);
                            } else {
                              (
                                await xanoAdminUpdateWhatsHotPUT.mutateAsync({
                                  discover_whatshot_id: currently_editing_id,
                                  facetVal: facetValue,
                                  imageData: attachmentValue,
                                  orderVal: orderValue,
                                  query: queryValue,
                                  title: titleValue,
                                  urlVal: urlValue,
                                })
                              )?.json;
                            }

                            setIs_saving(false);
                            setCurrently_editing_id(-1);
                            await refetchGetDiscoverWhatshot();
                            props.onUpdate?.();
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      loading={is_saving}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          { width: 180 }
                        ),
                        dimensions.width
                      )}
                      title={'Update'}
                    />
                  )}
                </>
              </View>
              {/* DeleteModal */}
              <Modal
                supportedOrientations={['portrait', 'landscape']}
                animationType={'fade'}
                presentationStyle={'fullScreen'}
                transparent={true}
                visible={item_to_delete}
              >
                <BlurView
                  tint={'default'}
                  {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
                  intensity={20}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
                      {
                        alignItems: {
                          minWidth: Breakpoints.Desktop,
                          value: 'center',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Desktop,
                          value: 'center',
                        },
                        padding: { minWidth: Breakpoints.Desktop, value: 16 },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* ModalContainer */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Desktop,
                          value: palettes.Brand.Surface,
                        },
                        borderColor: {
                          minWidth: Breakpoints.Desktop,
                          value: theme.colors.text.light,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Desktop,
                          value: 16,
                        },
                        borderWidth: {
                          minWidth: Breakpoints.Desktop,
                          value: 1,
                        },
                        maxWidth: { minWidth: Breakpoints.Desktop, value: 400 },
                        width: { minWidth: Breakpoints.Desktop, value: '90%' },
                      },
                      dimensions.width
                    )}
                  >
                    {/* ModalHeader */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Desktop,
                            value: 'center',
                          },
                          borderBottomWidth: {
                            minWidth: Breakpoints.Desktop,
                            value: 1,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Desktop,
                            value: theme.colors.text.light,
                          },
                          flexDirection: {
                            minWidth: Breakpoints.Desktop,
                            value: 'row',
                          },
                          marginBottom: {
                            minWidth: Breakpoints.Desktop,
                            value: 8,
                          },
                          padding: { minWidth: Breakpoints.Desktop, value: 16 },
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              flex: { minWidth: Breakpoints.Desktop, value: 1 },
                              fontFamily: {
                                minWidth: Breakpoints.Desktop,
                                value: 'SpaceGrotesk_600SemiBold',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Are You Sure ?'}
                      </Text>
                      <IconButton
                        onPress={() => {
                          try {
                            setItem_to_delete(null);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        size={32}
                        icon={'AntDesign/closecircleo'}
                      />
                    </View>
                    {/* ModalBody */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flex: { minWidth: Breakpoints.Desktop, value: 1 },
                          flexGrow: { minWidth: Breakpoints.Desktop, value: 1 },
                          flexShrink: {
                            minWidth: Breakpoints.Desktop,
                            value: 0,
                          },
                          padding: { minWidth: Breakpoints.Desktop, value: 16 },
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              marginBottom: {
                                minWidth: Breakpoints.Desktop,
                                value: 8,
                              },
                              marginLeft: {
                                minWidth: Breakpoints.Desktop,
                                value: 16,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'You are about to delete the "'}
                        {item_to_delete?.title}
                        {'". Are you sure that you want to continue?'}
                      </Text>
                    </View>
                    {/* ModalFooter */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Desktop,
                            value: 'center',
                          },
                          borderRadius: {
                            minWidth: Breakpoints.Desktop,
                            value: 9,
                          },
                          flexDirection: {
                            minWidth: Breakpoints.Desktop,
                            value: 'row',
                          },
                          justifyContent: {
                            minWidth: Breakpoints.Desktop,
                            value: 'space-around',
                          },
                          padding: { minWidth: Breakpoints.Desktop, value: 16 },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flex: { minWidth: Breakpoints.Desktop, value: 1 },
                            marginRight: {
                              minWidth: Breakpoints.Desktop,
                              value: 4,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* CancelButton */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              setItem_to_delete(null);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['OutlineButton']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ButtonStyles(theme)['OutlineButton']
                              .style,
                            dimensions.width
                          )}
                          title={'Cancel'}
                        />
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flex: { minWidth: Breakpoints.Desktop, value: 1 },
                            marginLeft: {
                              minWidth: Breakpoints.Desktop,
                              value: 4,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* DeleteButton */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                setCurrently_deleting_id(item_to_delete?.id);
                                (
                                  await xanoAdminDeleteWhatshotDELETE.mutateAsync(
                                    { discover_whatshot_id: item_to_delete?.id }
                                  )
                                )?.json;
                                setCurrently_deleting_id(null);
                                setItem_to_delete(null);
                                setTempRow(null);
                                await refetchGetDiscoverWhatshot();
                                props.onUpdate?.();
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          disabled={currently_deleting_id}
                          loading={currently_deleting_id}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: {
                                  minWidth: Breakpoints.Desktop,
                                  value: theme.colors.background.danger,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Yes, Stop'}
                        />
                      </View>
                    </View>
                  </View>
                </BlurView>
              </Modal>
            </>
          );
        }}
      </XanoAdminApi.FetchGetDiscoverWhatshotGET>
    </View>
  );
};

export default withTheme(WhatsHotTableBlock);

import React from 'react';
import {
  Button,
  IconButton,
  Picker,
  Pressable,
  SimpleStyleFlatList,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomComponents from '../custom-files/CustomComponents';
import FacetTranslator from '../global-functions/FacetTranslator';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { enableEdit: true, initialValue: [], onSave: () => {} };

const FacetPickerBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [currently_editing_id, setCurrently_editing_id] = React.useState(0);
  const [currently_editing_index, setCurrently_editing_index] =
    React.useState(-1);
  const [facetList, setFacetList] = React.useState(
    props.initialValue ?? defaultProps.initialValue
  );
  const [facetType, setFacetType] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState('');
  const splitFacet = initialValue => {
    const [facet, value] = (initialValue || ':').split(':');
    // console.log(facet, value)
    setFacetType(facet);
    setSelectedValue(value);
  };

  const facetOptions = () => {
    return [
      { value: '_tag_content_details.actor', label: 'Actor' },
      { value: '_tag_content_details.character', label: 'Character' },
      { value: '_tag_content_details.episode', label: 'Episode' },
      { value: '_tag_content_details.genre', label: 'Genre' },
      { value: '_tag_content_details.network', label: 'Network' },
      { value: '_tag_content_details.series_name', label: 'Series' },
      { value: '_tag_retail_details.brand', label: 'Brand' },
      { value: '_tag_retail_details.category', label: 'Category' },
      { value: '_tag_retail_details.gender', label: 'Gender' },
      { value: '_tag_retail_details.product_name', label: 'Product' },
      { value: '_tag_retail_details.retailer', label: 'Retailer' },
    ];
  };

  const updateFacet = (index, value) => {
    setFacetList(prev => [
      ...prev.slice(0, index),
      value,
      ...prev.slice(index + 1),
    ]);
  };

  const removeFacet = index => {
    setFacetList(prev => prev.filter((item, i) => i !== index));
  };
  React.useEffect(() => {
    props.onSave?.(facetList);
  }, [facetList]);
  React.useEffect(() => {
    try {
      /* hidden 'Run a Custom Function' action */
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <View
      style={StyleSheet.applyWidth(
        { marginBottom: 15, width: '100%' },
        dimensions.width
      )}
    >
      <>
        {!(facetList?.length > 0) ? null : (
          <SimpleStyleFlatList
            data={facetList}
            horizontal={false}
            inverted={false}
            keyExtractor={(listData, index) =>
              listData?.id ??
              listData?.uuid ??
              index?.toString() ??
              JSON.stringify(listData)
            }
            keyboardShouldPersistTaps={'never'}
            listKey={'nk5xTbUR'}
            nestedScrollEnabled={false}
            numColumns={1}
            onEndReachedThreshold={0.5}
            renderItem={({ item, index }) => {
              const listData = item;
              return (
                <>
                  {/* EditMode */}
                  <>
                    {!(currently_editing_index === index) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { gap: 8, padding: 8 },
                          dimensions.width
                        )}
                      >
                        <View>
                          <Picker
                            autoDismissKeyboard={true}
                            dropDownBorderColor={theme.colors.border.brand}
                            dropDownBorderRadius={8}
                            dropDownBorderWidth={1}
                            dropDownTextColor={theme.colors.text.strong}
                            iconSize={24}
                            leftIconMode={'inset'}
                            onValueChange={newPickerValue => {
                              try {
                                setFacetType(newPickerValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={'Select an option'}
                            selectedIconColor={theme.colors.text.strong}
                            selectedIconName={'Feather/check'}
                            selectedIconSize={20}
                            type={'solid'}
                            dropDownBackgroundColor={palettes.Brand.Surface}
                            mode={'dropdown-modal'}
                            options={facetOptions()}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: palettes.Brand.Surface,
                                fontFamily: 'SpaceGrotesk_400Regular',
                                margin: 0,
                                marginBottom: 0,
                                paddingBottom: 6,
                                paddingLeft: 0,
                                paddingRight: 6,
                                paddingTop: 6,
                              },
                              dimensions.width
                            )}
                            value={facetType}
                          />
                        </View>

                        <View>
                          <Utils.CustomCodeErrorBoundary>
                            <CustomComponents.Autocomplete
                              facetType={facetType}
                              theme={props.theme}
                              initialValue={selectedValue}
                              setSelectedValue={setSelectedValue}
                            />
                          </Utils.CustomCodeErrorBoundary>
                        </View>
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { paddingRight: 4, width: '50%' },
                              dimensions.width
                            )}
                          >
                            {/* CancelButton */}
                            <Button
                              iconPosition={'left'}
                              onPress={() => {
                                try {
                                  setCurrently_editing_index(-1);
                                  if (listData === '') {
                                    removeFacet(index);
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.ButtonStyles(theme)['Button']
                                .props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.ButtonStyles(theme)['Button']
                                  .style,
                                dimensions.width
                              )}
                              title={'Cancel'}
                            />
                          </View>
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              { paddingLeft: 4, width: '50%' },
                              dimensions.width
                            )}
                          >
                            {/* SaveButton */}
                            <Button
                              iconPosition={'left'}
                              onPress={() => {
                                try {
                                  updateFacet(
                                    currently_editing_index,
                                    facetType + (':' + selectedValue)
                                  );
                                  setCurrently_editing_index(-1);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.ButtonStyles(theme)['Button']
                                .props}
                              disabled={!selectedValue}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.ButtonStyles(theme)['Button']
                                  .style,
                                dimensions.width
                              )}
                              title={'Save'}
                            />
                          </View>
                        </View>
                      </View>
                    )}
                  </>
                  {/* ViewMode */}
                  <>
                    {!(index !== currently_editing_index) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: [
                              { minWidth: Breakpoints.BigScreen, value: 'row' },
                              { minWidth: Breakpoints.Mobile, value: 'row' },
                            ],
                            gap: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            dimensions.width
                          )}
                        >
                          {listData !== ''
                            ? FacetTranslator(listData)
                            : '[Not Selected]'}
                        </Text>
                        {/* EditButton */}
                        <>
                          {!(
                            props.enableEdit ?? defaultProps.enableEdit
                          ) ? null : (
                            <IconButton
                              onPress={() => {
                                try {
                                  setCurrently_editing_index(index);
                                  splitFacet(listData);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              icon={'Feather/edit'}
                              size={18}
                            />
                          )}
                        </>
                        {/* DeleteButton */}
                        <>
                          {!(
                            props.enableEdit ?? defaultProps.enableEdit
                          ) ? null : (
                            <IconButton
                              onPress={() => {
                                try {
                                  removeFacet(index);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              icon={'Feather/trash'}
                              size={18}
                            />
                          )}
                        </>
                      </View>
                    )}
                  </>
                </>
              );
            }}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
          />
        )}
      </>
      {/* AddMode */}
      <>
        {!(props.enableEdit ?? defaultProps.enableEdit) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                gap: 8,
                justifyContent: 'space-between',
                padding: 12,
              },
              dimensions.width
            )}
          >
            <View>
              <Pressable
                onPress={() => {
                  try {
                    undefined;
                    setCurrently_editing_index(facetList?.length);
                    setFacetList(facetList.concat(['']));
                  } catch (err) {
                    console.error(err);
                  }
                }}
                disabled={facetList.includes('')}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.branding.primary,
                        fontFamily: 'SpaceGrotesk_500Medium',
                        textDecorationLine: 'underline',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Add Facet'}
                </Text>
              </Pressable>
            </View>
          </View>
        )}
      </>
    </View>
  );
};

export default withTheme(FacetPickerBlock);

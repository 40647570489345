import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const addTrendingPOST = async (
  Constants,
  {
    facetValue,
    imageData,
    order,
    promotionIdValue,
    queryValue,
    titleValue,
    urlValue,
  },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/discover_trending`;
  const options = {
    body: JSON.stringify({
      title: titleValue,
      url: urlValue,
      promotions_id: promotionIdValue,
      facet: facetValue,
      order: order,
      query: queryValue,
      imageData: imageData,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAddTrendingPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => addTrendingPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Trending', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Trending');
        queryClient.invalidateQueries('Trendings');
      },
    }
  );
};

export const FetchAddTrendingPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  facetValue,
  imageData,
  order,
  promotionIdValue,
  queryValue,
  titleValue,
  urlValue,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddTrendingPOST(
    {
      facetValue,
      imageData,
      order,
      promotionIdValue,
      queryValue,
      titleValue,
      urlValue,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddTrending: refetch });
};

export const addWhatsHotPOST = async (
  Constants,
  { facetVal, imageData, orderVal, query, title, urlVal },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/discover_whatshot`;
  const options = {
    body: JSON.stringify({
      title: title,
      url: urlVal,
      facet: facetVal,
      order: orderVal,
      query: query,
      imageData: imageData,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAddWhatsHotPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => addWhatsHotPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('WhatsHot', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('WhatsHot');
        queryClient.invalidateQueries('WhatsHots');
      },
    }
  );
};

export const FetchAddWhatsHotPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  facetVal,
  imageData,
  orderVal,
  query,
  title,
  urlVal,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddWhatsHotPOST(
    { facetVal, imageData, orderVal, query, title, urlVal },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddWhatsHot: refetch });
};

export const approveAdsGET = async (Constants, { ads_id }, handlers = {}) => {
  const paramsDict = {};
  if (ads_id !== undefined) {
    paramsDict['ads_id'] = ads_id;
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/approve_ad${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useApproveAdsGET = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => approveAdsGET(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Ads', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Ad');
        queryClient.invalidateQueries('Ads');
      },
    }
  );
};

export const FetchApproveAdsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  ads_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useApproveAdsGET(
    { ads_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchApproveAds: refetch });
};

export const approveTagsPOST = async (
  Constants,
  { tags_ids },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/approve_tags`;
  const options = {
    body: JSON.stringify({ tags_ids: tags_ids }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useApproveTagsPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => approveTagsPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Tags', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Tag');
        queryClient.invalidateQueries('Tags');
      },
    }
  );
};

export const FetchApproveTagsPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  tags_ids,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useApproveTagsPOST(
    { tags_ids },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchApproveTags: refetch });
};

export const createNewAdPOST = async (
  Constants,
  {
    ad_name,
    attachments,
    budget,
    display_at,
    keywords,
    random_order,
    start_date,
  },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/create_ad`;
  const options = {
    body: JSON.stringify({
      ad_name: ad_name,
      start_date: start_date,
      keywords: keywords,
      display_at: display_at,
      random_order: random_order,
      attachments: attachments,
      budget: budget,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateNewAdPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createNewAdPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Ads', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Ad');
        queryClient.invalidateQueries('Ads');
      },
    }
  );
};

export const FetchCreateNewAdPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  ad_name,
  attachments,
  budget,
  display_at,
  keywords,
  random_order,
  start_date,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateNewAdPOST(
    {
      ad_name,
      attachments,
      budget,
      display_at,
      keywords,
      random_order,
      start_date,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateNewAd: refetch });
};

export const deletePromotionImageDELETE = async (
  Constants,
  { promotionImagesId },
  handlers = {}
) => {
  const paramsDict = {};
  if (promotionImagesId !== undefined) {
    paramsDict['promotion_images_id'] = promotionImagesId;
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/promotion_images${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeletePromotionImageDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deletePromotionImageDELETE(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('PromotionImages', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('PromotionImage');
        queryClient.invalidateQueries('PromotionImages');
      },
    }
  );
};

export const deleteTrendingDELETE = async (
  Constants,
  { discover_trending_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/discover_trending/${encodeQueryParam(
    discover_trending_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteTrendingDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteTrendingDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Trending', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Trending');
        queryClient.invalidateQueries('Trendings');
      },
    }
  );
};

export const deleteWhatshotDELETE = async (
  Constants,
  { discover_whatshot_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/discover_whatshot/${encodeQueryParam(
    discover_whatshot_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteWhatshotDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteWhatshotDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('WhatsHot', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('WhatsHot');
        queryClient.invalidateQueries('WhatsHots');
      },
    }
  );
};

export const facetAutocompleteGET = async (
  Constants,
  { facetType, value },
  handlers = {}
) => {
  const paramsDict = {};
  if (facetType !== undefined) {
    paramsDict['facet_type'] = facetType;
  }
  if (value !== undefined) {
    paramsDict['value'] = value;
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/facet_autocomplete${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useFacetAutocompleteGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAdminFacetAutocompleteGET', args],
    () => facetAutocompleteGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoAdminFacetAutocompleteGETS']),
    }
  );
};

export const FetchFacetAutocompleteGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  facetType,
  value,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useFacetAutocompleteGET(
    { facetType, value },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchFacetAutocomplete: refetch });
};

export const getBalanceGET = async (Constants, { refresh }, handlers = {}) => {
  const paramsDict = {};
  if (refresh !== undefined) {
    paramsDict['refresh'] = refresh;
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/balance${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetBalanceGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Balance', args],
    () => getBalanceGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Balances']),
    }
  );
};

export const FetchGetBalanceGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refresh,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBalanceGET(
    { refresh },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBalance: refetch });
};

export const getDiscoverDataGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/get_discover_data`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetDiscoverDataGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAdminGetDiscoverDataGET', args],
    () => getDiscoverDataGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoAdminGetDiscoverDataGETS']),
    }
  );
};

export const FetchGetDiscoverDataGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetDiscoverDataGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetDiscoverData: refetch });
};

export const getDiscoverTrendingGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/discover_trending`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetDiscoverTrendingGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Trendings', args],
    () => getDiscoverTrendingGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetDiscoverTrendingGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetDiscoverTrendingGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetDiscoverTrending: refetch,
  });
};

export const getDiscoverWhatshotGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/discover_whatshot`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetDiscoverWhatshotGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['WhatsHots', args],
    () => getDiscoverWhatshotGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetDiscoverWhatshotGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetDiscoverWhatshotGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetDiscoverWhatshot: refetch,
  });
};

export const getMyAdsGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/get_my_ads`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetMyAdsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['Ads', args], () => getMyAdsGET(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchGetMyAdsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetMyAdsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetMyAds: refetch });
};

export const getPromotionsGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/promotions`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPromotionsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Promotions', args],
    () => getPromotionsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetPromotionsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPromotionsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPromotions: refetch });
};

export const getTransactionsGET = async (
  Constants,
  { refresh },
  handlers = {}
) => {
  const paramsDict = {};
  if (refresh !== undefined) {
    paramsDict['refresh'] = refresh;
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/transaction${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetTransactionsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Transaction', args],
    () => getTransactionsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Transactions']),
    }
  );
};

export const FetchGetTransactionsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refresh,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetTransactionsGET(
    { refresh },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetTransactions: refetch });
};

export const getUnapprovedAdsGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/get_unapproved_ads`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetUnapprovedAdsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['UnApprovedAds', args],
    () => getUnapprovedAdsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUnapprovedAdsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUnapprovedAdsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUnapprovedAds: refetch });
};

export const getUnapprovedTagsGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/get_unapproved_tags`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetUnapprovedTagsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Tags', args],
    () => getUnapprovedTagsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUnapprovedTagsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUnapprovedTagsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUnapprovedTags: refetch });
};

export const getUsersWithPushTokensGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/get_users_with_token`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetUsersWithPushTokensGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['User', args],
    () => getUsersWithPushTokensGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Users']),
    }
  );
};

export const FetchGetUsersWithPushTokensGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUsersWithPushTokensGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetUsersWithPushTokens: refetch,
  });
};

export const loginPOST = async (
  Constants,
  { email, password },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/auth/login`;
  const options = {
    body: JSON.stringify({ email: email, password: password }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAdminLoginPOST', args],
    () => loginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoAdminLoginPOSTS']),
    }
  );
};

export const FetchLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useLoginPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLogin: refetch });
};

export const moveDiscoverTrendingGET = async (
  Constants,
  { direction, discover_trending_id },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['direction'] = direction !== undefined ? direction : '';
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/discover_trending_move/${encodeQueryParam(
    discover_trending_id
  )}${renderQueryString(paramsDict, 'brackets')}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMoveDiscoverTrendingGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAdminMoveDiscoverTrendingGET', args],
    () => moveDiscoverTrendingGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoAdminMoveDiscoverTrendingGETS']),
    }
  );
};

export const FetchMoveDiscoverTrendingGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  direction,
  discover_trending_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMoveDiscoverTrendingGET(
    { direction, discover_trending_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchMoveDiscoverTrending: refetch,
  });
};

export const moveDiscoverWhatshotGET = async (
  Constants,
  { direction, discover_whatshot_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (direction !== undefined) {
    paramsDict['direction'] = direction;
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/discover_whatshot_move/${encodeQueryParam(
    discover_whatshot_id
  )}${renderQueryString(paramsDict, 'brackets')}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMoveDiscoverWhatshotGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAdminMoveDiscoverWhatshotGET', args],
    () => moveDiscoverWhatshotGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoAdminMoveDiscoverWhatshotGETS']),
    }
  );
};

export const FetchMoveDiscoverWhatshotGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  direction,
  discover_whatshot_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMoveDiscoverWhatshotGET(
    { direction, discover_whatshot_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchMoveDiscoverWhatshot: refetch,
  });
};

export const movePromotionGET = async (
  Constants,
  { direction, promotion_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (direction !== undefined) {
    paramsDict['direction'] = direction;
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/promotion_move/${encodeQueryParam(
    promotion_id
  )}${renderQueryString(paramsDict, 'brackets')}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMovePromotionGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAdminMovePromotionGET', args],
    () => movePromotionGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoAdminMovePromotionGETS']),
    }
  );
};

export const FetchMovePromotionGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  direction,
  promotion_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMovePromotionGET(
    { direction, promotion_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMovePromotion: refetch });
};

export const sendNotificationToAllPOST = async (
  Constants,
  { body, id, index, query, screen, title },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/send_notification_all`;
  const options = {
    body: JSON.stringify({
      title: title,
      body: body,
      screen: screen,
      id: id,
      query: query,
      index: index,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSendNotificationToAllPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAdminSendNotificationToAllPOST', args],
    () => sendNotificationToAllPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoAdminSendNotificationToAllPOSTS']),
    }
  );
};

export const FetchSendNotificationToAllPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  body,
  id,
  index,
  query,
  screen,
  title,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSendNotificationToAllPOST(
    { body, id, index, query, screen, title },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSendNotificationToAll: refetch,
  });
};

export const sendNotificationToSubsetPOST = async (
  Constants,
  { body, id, index, push_token, query, screen, title },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/send_notification_subset`;
  const options = {
    body: JSON.stringify({
      push_tokens: push_token,
      title: title,
      body: body,
      screen: screen,
      id: id,
      query: query,
      index: index,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSendNotificationToSubsetPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAdminSendNotificationToSubsetPOST', args],
    () => sendNotificationToSubsetPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoAdminSendNotificationToSubsetPOSTS',
        ]),
    }
  );
};

export const FetchSendNotificationToSubsetPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  body,
  id,
  index,
  push_token,
  query,
  screen,
  title,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSendNotificationToSubsetPOST(
    { body, id, index, push_token, query, screen, title },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSendNotificationToSubset: refetch,
  });
};

export const signupPOST = async (
  Constants,
  { email, handle, name, password },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/auth/signup`;
  const options = {
    body: JSON.stringify({
      name: name,
      email: email,
      password: password,
      handle: handle,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSignupPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAdminSignupPOST', args],
    () => signupPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoAdminSignupPOSTS']),
    }
  );
};

export const FetchSignupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  handle,
  name,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSignupPOST(
    { email, handle, name, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSignup: refetch });
};

export const toggleActiveGET = async (Constants, { ads_id }, handlers = {}) => {
  const paramsDict = {};
  if (ads_id !== undefined) {
    paramsDict['ads_id'] = ads_id;
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/toggle_ad_active${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useToggleActiveGET = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => toggleActiveGET(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Ads', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Ad');
        queryClient.invalidateQueries('Ads');
      },
    }
  );
};

export const FetchToggleActiveGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  ads_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useToggleActiveGET(
    { ads_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchToggleActive: refetch });
};

export const toggleApproveGET = async (
  Constants,
  { ads_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (ads_id !== undefined) {
    paramsDict['ads_id'] = ads_id;
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/toggle_ad_approve${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useToggleApproveGET = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => toggleApproveGET(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Ads', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Ad');
        queryClient.invalidateQueries('Ads');
      },
    }
  );
};

export const FetchToggleApproveGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  ads_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useToggleApproveGET(
    { ads_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchToggleApprove: refetch });
};

export const tooglePromotionForDicoverGET = async (
  Constants,
  { promotion_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/toggle_promotion/${encodeQueryParam(
    promotion_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useTooglePromotionForDicoverGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAdminTooglePromotionForDicoverGET', args],
    () => tooglePromotionForDicoverGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoAdminTooglePromotionForDicoverGETS',
        ]),
    }
  );
};

export const FetchTooglePromotionForDicoverGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  promotion_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useTooglePromotionForDicoverGET(
    { promotion_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchTooglePromotionForDicover: refetch,
  });
};

export const updateAdPOST = async (
  Constants,
  {
    ad_name,
    ads_id,
    attachments,
    display_at,
    end_date,
    keywords,
    random_order,
    start_date,
  },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/update_ad`;
  const options = {
    body: JSON.stringify({
      ads_id: ads_id,
      ad_name: ad_name,
      start_date: start_date,
      end_date: end_date,
      keywords: keywords,
      display_at: display_at,
      random_order: random_order,
      attachments: attachments,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateAdPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateAdPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Ads', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Ad');
        queryClient.invalidateQueries('Ads');
      },
    }
  );
};

export const FetchUpdateAdPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  ad_name,
  ads_id,
  attachments,
  display_at,
  end_date,
  keywords,
  random_order,
  start_date,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateAdPOST(
    {
      ad_name,
      ads_id,
      attachments,
      display_at,
      end_date,
      keywords,
      random_order,
      start_date,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdateAd: refetch });
};

export const updateAdBudgetPATCH = async (
  Constants,
  { ad_id, balance },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/update_ad_budget/${encodeQueryParam(
    ad_id
  )}`;
  const options = {
    body: JSON.stringify({ balance: balance }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateAdBudgetPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateAdBudgetPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Ads', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Ad');
        queryClient.invalidateQueries('Ads');
      },
    }
  );
};

export const updateBalancePOST = async (
  Constants,
  { amount, note, type },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/balance`;
  const options = {
    body: JSON.stringify({ amount: amount, note: note, type: type }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateBalancePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateBalancePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Balance', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Balance');
        queryClient.invalidateQueries('Balances');
      },
    }
  );
};

export const FetchUpdateBalancePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  amount,
  note,
  type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateBalancePOST(
    { amount, note, type },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdateBalance: refetch });
};

export const updateTrendingPUT = async (
  Constants,
  {
    discover_tranding_id,
    facetValue,
    imageData,
    promotionsIdValue,
    queryValue,
    titleValue,
    urlValue,
  },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/discover_trending/${encodeQueryParam(
    discover_tranding_id
  )}`;
  const options = {
    body: JSON.stringify({
      title: titleValue,
      url: urlValue,
      promotions_id: promotionsIdValue,
      facet: facetValue,
      query: queryValue,
      imageData: imageData,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateTrendingPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateTrendingPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Trending', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Trending');
        queryClient.invalidateQueries('Trendings');
      },
    }
  );
};

export const updateWhatsHotPUT = async (
  Constants,
  { discover_whatshot_id, facetVal, imageData, orderVal, query, title, urlVal },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/discover_whatshot/${encodeQueryParam(
    discover_whatshot_id
  )}`;
  const options = {
    body: JSON.stringify({
      title: title,
      url: urlVal,
      facet: facetVal,
      order: orderVal,
      query: query,
      imageData: imageData,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateWhatsHotPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateWhatsHotPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('WhatsHot', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('WhatsHot');
        queryClient.invalidateQueries('WhatsHots');
      },
    }
  );
};

export const uploadPromotionImagePOST = async (
  Constants,
  { image_data, promotions_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:LQVzgUmu/promotion_images`;
  const options = {
    body: JSON.stringify({
      promotions_id: promotions_id,
      image_data: image_data,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['MA_AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUploadPromotionImagePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      uploadPromotionImagePOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('PromotionImages', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('PromotionImage');
        queryClient.invalidateQueries('PromotionImages');
      },
    }
  );
};

export const FetchUploadPromotionImagePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  image_data,
  promotions_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUploadPromotionImagePOST(
    { image_data, promotions_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchUploadPromotionImage: refetch,
  });
};

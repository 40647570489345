import React from 'react';
import {
  Button,
  LinearGradient,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { H2 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as UnsplashApi from '../apis/UnsplashApi.js';
import * as XanoAdminApi from '../apis/XanoAdminApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomComponents from '../custom-files/CustomComponents';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AuthSignupScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [confirmPasswordValue, setConfirmPasswordValue] = React.useState('');
  const [emailValue, setEmailValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [handleErrorMessage, setHandleErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [nameValue, setNameValue] = React.useState('');
  const [passwordValue, setPasswordValue] = React.useState('');
  const [uniqueNameValue, setUniqueNameValue] = React.useState('');
  const validPassword = password => {
    if (password === '') return 'Minimum 8 characters is required';

    // Minimum of 8 characters
    const minLength = /^.{8,}$/;
    // At least 1 lowercase character
    const hasLowercase = /[a-z]/;
    // At least 1 uppercase character
    const hasUppercase = /[A-Z]/;

    if (!minLength.test(password)) return 'Minimum 8 characters is required';
    if (!hasLowercase.test(password))
      return 'Must include at least 1 lowercase';
    if (!hasUppercase.test(password))
      return 'Must include at least 1 uppercase';

    return true;
  };

  const isValidHandle = () => {
    return (
      uniqueNameValue && (!handleErrorMessage || handleErrorMessage === '')
    );
  };

  const validateForm = () => {
    if (nameValue === '') {
      setErrorMessage('Please enter your name');
      return false;
    }

    if (emailValue === '') {
      setErrorMessage('Please enter your email');
      return false;
    }

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue) === false) {
      setErrorMessage('Invalid e-mail address');
      return false;
    }

    if (uniqueNameValue === '') {
      setErrorMessage('Handle is required');
      return false;
    }

    if (!isValidHandle()) {
      setErrorMessage('Handle is not valid');
      return false;
    }

    if (passwordValue === '') {
      setErrorMessage('Please enter your password');
      return false;
    }

    if (confirmPasswordValue === '') {
      setErrorMessage('Please confirm your password');
      return false;
    }

    if (passwordValue !== confirmPasswordValue) {
      setErrorMessage('Passwords do not match');
      return false;
    }

    if (validPassword(passwordValue) !== true) {
      setErrorMessage('Invalid password');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* Container */}
      <Surface
        {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
        elevation={1}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            {
              borderRadius: 16,
              flex: 1,
              flexDirection: 'row',
              height: { minWidth: Breakpoints.Laptop, value: '90%' },
              marginLeft: { minWidth: Breakpoints.Laptop, value: '5%' },
              marginTop: { minWidth: Breakpoints.Laptop, value: '5%' },
              overflow: 'hidden',
              width: { minWidth: Breakpoints.Laptop, value: '90%' },
            }
          ),
          dimensions.width
        )}
      >
        {/* LeftView 2 */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.Brand.Surface,
                  flex: 1,
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              <UnsplashApi.FetchGetRandomPhotoGET
                clientId={'L5Ami1Q3-LG1fQW4QZIBYKHDHscWdfdRJmhx0ZpnDh0'}
                orientation={'landscape'}
                query={'fashion'}
              >
                {({ loading, error, data, refetchGetRandomPhoto }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <ImageBackground
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageBackgroundStyles(theme)[
                        'Image Background'
                      ].props}
                      source={{ uri: `${fetchData?.urls?.regular}` }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageBackgroundStyles(theme)[
                            'Image Background'
                          ].style,
                          {
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <BlurView
                        intensity={50}
                        tint={'default'}
                        {...GlobalStyles.BlurViewStyles(theme)['Blur View']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.BlurViewStyles(theme)['Blur View']
                              .style,
                            {
                              alignItems: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              justifyContent: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Logo */}
                        <Image
                          resizeMode={'cover'}
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          source={imageSource(Images['LogoPurpleFull'])}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              {
                                height: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 150,
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 350,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        />
                        <LinearGradient
                          color1={theme.colors.branding.primary}
                          color2={theme.colors.branding.secondary}
                          endX={100}
                          endY={100}
                          startX={0}
                          startY={0}
                          {...GlobalStyles.LinearGradientStyles(theme)[
                            'Linear Gradient'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.LinearGradientStyles(theme)[
                                'Linear Gradient'
                              ].style,
                              {
                                alignItems: 'center',
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 16,
                                },
                                flex: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0,
                                },
                                height: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 200,
                                },
                                justifyContent: 'center',
                                opacity: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0.5,
                                },
                                position: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'absolute',
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 380,
                                },
                                zIndex: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        />
                      </BlurView>
                    </ImageBackground>
                  );
                }}
              </UnsplashApi.FetchGetRandomPhotoGET>
            </View>
          )}
        </>
        {/* RightView */}
        <View
          style={StyleSheet.applyWidth(
            { backgroundColor: palettes.Brand.Surface, flex: 1 },
            dimensions.width
          )}
        >
          <SimpleStyleKeyboardAwareScrollView
            enableAutomaticScroll={false}
            enableOnAndroid={false}
            enableResetScrollToCoords={false}
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
            viewIsInsideTabBar={false}
            style={StyleSheet.applyWidth(
              { flex: 1, justifyContent: 'center' },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  position: 'absolute',
                  top: 20,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Logo */}
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['LogoPurpleFull'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 150, width: 350 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      fontFamily: 'SpaceGrotesk_600SemiBold',
                      fontSize: 20,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Admin Panel'}
              </Text>
            </View>
            {/* Form */}
            <View
              style={StyleSheet.applyWidth(
                { backgroundColor: palettes.Brand.Surface, padding: 16 },
                dimensions.width
              )}
            >
              <H2
                selectable={false}
                {...GlobalStyles.H2Styles(theme)['H2'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.H2Styles(theme)['H2'].style, {
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Signup'}
              </H2>
              {/* Name */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['FormLabel'].style,
                    dimensions.width
                  )}
                >
                  {'Name'}
                </Text>
                {/* NameInput */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newNameInputValue => {
                    try {
                      setNameValue(newNameInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['FormTextInput']
                    .props}
                  keyboardType={'email-address'}
                  placeholder={'Enter Name'}
                  placeholderTextColor={theme.colors.text.light}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['FormTextInput'].style,
                    dimensions.width
                  )}
                  value={nameValue}
                />
              </View>
              {/* Email */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['FormLabel'].style,
                    dimensions.width
                  )}
                >
                  {'Email'}
                </Text>
                {/* EmailInput */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newEmailInputValue => {
                    try {
                      setEmailValue(newEmailInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['FormTextInput']
                    .props}
                  keyboardType={'email-address'}
                  placeholder={'Enter Email'}
                  placeholderTextColor={theme.colors.text.light}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['FormTextInput'].style,
                    dimensions.width
                  )}
                  value={emailValue}
                />
              </View>
              {/* Handle */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['FormLabel'].style,
                    dimensions.width
                  )}
                >
                  {'Create a Handle'}
                </Text>
                {/* HandleCustomCodeInput */}
                <Utils.CustomCodeErrorBoundary>
                  <CustomComponents.HandleTextInput
                    theme={props.theme}
                    uniqueNameValue={uniqueNameValue}
                    setUniqueNameValue={setUniqueNameValue}
                    setHandleErrorMessage={setHandleErrorMessage}
                  />
                </Utils.CustomCodeErrorBoundary>
                <>
                  {uniqueNameValue ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors.text.medium,
                            fontSize: 11,
                            marginTop: 4,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Enter a unique Handle beginning with @'}
                    </Text>
                  )}
                </>
                {/* HandleErrorMessageText */}
                <>
                  {!handleErrorMessage ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors.background.danger,
                              },
                              {
                                minWidth: Breakpoints.Desktop,
                                value: theme.colors.background.danger,
                              },
                            ],
                            fontSize: 11,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {handleErrorMessage}
                    </Text>
                  )}
                </>
                {/* HandleSuccessMessageText */}
                <>
                  {!isValidHandle() ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { color: palettes.App['App Green'], fontSize: 11 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'You can use this handle!'}
                    </Text>
                  )}
                </>
              </View>
              {/* Password */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['FormLabel'].style,
                    dimensions.width
                  )}
                >
                  {'Password'}
                </Text>
                {/* PasswordInput */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newPasswordInputValue => {
                    try {
                      setPasswordValue(newPasswordInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['FormTextInput']
                    .props}
                  placeholder={'Enter Password'}
                  placeholderTextColor={theme.colors.text.light}
                  secureTextEntry={true}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['FormTextInput'].style,
                    dimensions.width
                  )}
                  value={passwordValue}
                />
              </View>
              {/* ConfirmPassword */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['FormLabel'].style,
                    dimensions.width
                  )}
                >
                  {'Confirm Password'}
                </Text>
                {/* ConfirmPasswordInput */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newConfirmPasswordInputValue => {
                    try {
                      setConfirmPasswordValue(newConfirmPasswordInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['FormTextInput']
                    .props}
                  placeholder={'Confirm Password'}
                  placeholderTextColor={theme.colors.text.light}
                  secureTextEntry={true}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['FormTextInput'].style,
                    dimensions.width
                  )}
                  value={confirmPasswordValue}
                />
              </View>
              {/* ErrorText */}
              <>
                {!errorMessage ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors.background.danger,
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors.background.danger,
                            },
                          ],
                          fontSize: 10,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {errorMessage}
                  </Text>
                )}
              </>
              {/* SignupButton */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      if (!validateForm()) {
                        return;
                      }
                      setIsLoading(true);
                      const signupResult = (
                        await XanoAdminApi.signupPOST(Constants, {
                          email: emailValue,
                          handle: uniqueNameValue,
                          name: nameValue,
                          password: passwordValue,
                        })
                      )?.json;
                      setErrorMessage(signupResult?.message);
                      if (signupResult?.message) {
                        return;
                      }
                      setIsLoading(false);
                      undefined;
                      undefined;
                      navigation.navigate('DashboardScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                disabled={isLoading}
                loading={isLoading}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    { marginTop: 15 }
                  ),
                  dimensions.width
                )}
                title={'Signup'}
              />
            </View>
          </SimpleStyleKeyboardAwareScrollView>
        </View>
      </Surface>
    </ScreenContainer>
  );
};

export default withTheme(AuthSignupScreen);

import palettes from '../themes/palettes';
import React, { memo, useCallback, useRef, useState, useEffect } from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAdminApi from '../apis/XanoAdminApi.js';
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown';

import { useDebounce } from './Hooks';

import {
  TextInput,
  useWindowDimensions,
  Dimensions,
  Animated,
  Text,
  View,
  Platform,
  Image,
} from 'react-native';
import * as StyleSheet from '../utils/StyleSheet';

export const Autocomplete = memo(
  ({ facetType, theme, initialValue, setSelectedValue }) => {
    const Constants = GlobalVariables.useValues();
    const dimensions = useWindowDimensions();

    const [loading, setLoading] = useState(false);
    const [suggestionsList, setSuggestionsList] = useState(null);
    const [selectedItem, setSelectedItem] = useState(initialValue);
    const dropdownController = useRef(null);

    const searchRef = useRef(null);

    useEffect(() => {
      if (!selectedItem) return;

      setSelectedValue?.(selectedItem);
    }, [selectedItem]);

    const getSuggestions = useCallback(
      async q => {
        if (typeof q !== 'string') {
          setSuggestionsList(null);
          return;
        }
        setLoading(true);

        const items = (
          await XanoAdminApi.facetAutocompleteGET(Constants, {
            facetType,
            value: q,
          })
        )?.json;

        const suggestions = items.map(item => ({
          id: item,
          title: item,
        }));
        setSuggestionsList(suggestions);
        setLoading(false);
      },
      [facetType]
    );

    const onClearPress = useCallback(() => {
      setSuggestionsList(null);
    }, []);

    const onOpenSuggestionsList = useCallback(isOpened => {}, []);

    return (
      <>
        <View
          style={[
            { flex: 1, flexDirection: 'row', alignItems: 'center' },
            Platform.select({ ios: { zIndex: 1 } }),
          ]}
        >
          <AutocompleteDropdown
            ref={searchRef}
            controller={controller => {
              dropdownController.current = controller;
            }}
            direction={Platform.select({ ios: 'down' })}
            dataSet={suggestionsList}
            onChangeText={getSuggestions}
            onSelectItem={item => {
              item && setSelectedItem(item.id);
            }}
            debounce={600}
            suggestionsListMaxHeight={dimensions.height * 0.4}
            onClear={onClearPress}
            //  onSubmit={(e) => onSubmitSearch(e.nativeEvent.text)}
            onOpenSuggestionsList={onOpenSuggestionsList}
            loading={loading}
            useFilter={false} // set false to prevent rerender twice
            textInputProps={{
              placeholder: 'Search...',
              autoCorrect: false,
              autoCapitalize: 'none',
              disabled: !facetType,
              style: {
                ...GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                color: theme.colors.text.strong,
                paddingLeft: 15,
                paddingTop: 6,
                paddingBottom: 6,
              },
            }}
            inputContainerStyle={{
              backgroundColor: palettes.Brand['Surface'],
            }}
            suggestionsListContainerStyle={{
              backgroundColor: palettes.Brand['Surface'],
            }}
            containerStyle={{ flexGrow: 1, flexShrink: 1 }}
            renderItem={(item, text) => (
              <Text style={{ color: theme.colors.text.strong, padding: 15 }}>
                {item.title}
              </Text>
            )}
            showChevron={false}
            closeOnBlur={false}
            inputHeight={38}
          />
        </View>
      </>
    );
  }
);

export const HandleTextInput = ({
  theme,
  disabled,
  uniqueNameValue,
  setUniqueNameValue,
  setHandleErrorMessage,
}) => {
  const Constants = GlobalVariables.useValues();
  const dimensions = useWindowDimensions();

  const attachPrefix = str => {
    if (str.length > 0 && str[0] !== '@') {
      return '@' + str;
    }

    return str;
  };

  const debounceUniqueName = useDebounce(uniqueNameValue, 500);

  useEffect(() => {
    const handler = async () => {
      try {
        setHandleErrorMessage(null);

        if (uniqueNameValue === '@') {
          setHandleErrorMessage('Please enter a valid handle');
          return;
        }

        const response = await fetch(
          `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/unique_handle_check?handle=${uniqueNameValue}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const isUniqueName = await response.json();

        if (
          !isUniqueName &&
          uniqueNameValue !== Constants.MA_USER_DATA?._profile_of_user?.handle
        ) {
          setHandleErrorMessage('Handle is already taken');
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (debounceUniqueName && !disabled) handler();
  }, [debounceUniqueName, disabled]);

  return (
    <TextInput
      onChangeText={newUniqueNameValue => {
        setUniqueNameValue(attachPrefix(newUniqueNameValue));

        if (!newUniqueNameValue || newUniqueNameValue === '') {
          setHandleErrorMessage(null);
        }
      }}
      // style={{
      //   borderBottomWidth: 1,
      //   borderColor: palettes.App['Border Color'],
      //   borderLeftWidth: 1,
      //   borderRadius: 10,
      //   borderRightWidth: 1,
      //   borderTopWidth: 1,
      //   color: palettes.App['Custom Color'],
      //   fontFamily: 'SpaceGrotesk_400Regular',
      //   fontSize: isBigScreen ? 16 : 12,
      //   height: 48,
      //   marginTop: 10,
      //   paddingBottom: 8,
      //   paddingLeft: 15,
      //   paddingRight: 8,
      //   paddingTop: 8,
      // }}
      style={StyleSheet.applyWidth(
        GlobalStyles.TextInputStyles(theme)['Text Input'].style,
        dimensions.width
      )}
      value={uniqueNameValue}
      placeholder={'Enter a handle beginning with @'}
      editable={true}
      placeholderTextColor={theme.colors.text.light}
      textContentType={'username'}
      disabled={disabled}
    />
  );
};
export const FadeCarousel = ({
  images,
  start,
  stillDuration,
  fadeDuration,
  containerStyle,
  elementStyles,
  height = 100,
  width = 100,
  delay = 0,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const animationOut = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    setCurrentIndex(images.length - 1);

    if (start) {
      waitNSeconds((stillDuration + delay) / 1000).then(() =>
        fadeOutAnimation()
      );
    }
  }, [start]);

  const fadeOutAnimation = () => {
    animationOut.setValue(1);
    Animated.timing(animationOut, {
      toValue: 0,
      duration: fadeDuration,
      useNativeDriver: true,
    }).start(async () => {
      await waitNSeconds(stillDuration / 1000);

      setCurrentIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : images.length - 1
      );

      if (start) fadeOutAnimation();
    });
  };

  const waitNSeconds = seconds =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve({});
      }, seconds * 1000);
    });

  let eleStyles = elementStyles
    ? [elementStyles, { position: 'absolute' }]
    : { position: 'absolute', height: 'auto%', width: 'auto%' }; //, height: 'auto', width: 'auto'

  return (
    <View style={[{ zIndex: 0, width: '100%' }, containerStyle]}>
      {images.map((image, i) => {
        let styleA = { opacity: 1 };
        let zIndex = 0;

        if (i === currentIndex) {
          zIndex = 2;
          styleA = { opacity: animationOut };
        }

        if (
          i === currentIndex - 1 ||
          (currentIndex === 0 && i === images.length - 1)
        ) {
          zIndex = 1;
        }

        styleA.zIndex = zIndex;

        return (
          <Animated.View
            key={i}
            style={[styleA, eleStyles, { height, width: '100%' }]}
          >
            <Image
              source={{
                uri: image?.replace('f_webp/', 'f_webp/ar_20:13,c_crop/'),
              }}
              style={{ height: '100%', width: '100%' }}
              resizeMode="cover"
            />
          </Animated.View>
        );
      })}
    </View>
  );
};

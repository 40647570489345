import palettes from '../themes/palettes';
import React from 'react';
import { StyleSheet, Modal, View, Text } from 'react-native';
import { Button, Surface, IconButton } from '@draftbit/ui';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariableContext from '../config/GlobalVariableContext';
import * as BalanceServiceApi from '../apis/XanoAdminApi.js';
import * as StripeServiceApi from '../apis/StripeServiceApi.js';
import centToDollar from '../global-functions/centToDollar';

export const PaymentBtn = ({
  navigation,
  theme,
  amount,
  description,
  setErrorMessage,
  verify,
  callback,
}) => {
  const variables = GlobalVariableContext.useValues();

  const [isLoading, setIsLoading] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState();
  const [showPaymentModal, setShowPaymentModal] = React.useState(false);

  const stripePromise = loadStripe(variables.STRIPE_KEY); // 'pk_test_51OMYXPGpzrKFm4uh7JqtHySbfEWtJSkQJNcYAMYWaHOUxVzvHvSEW5f1Zfsiiio5hbiu6fLlXsSdpxfArKB3on6W004sa5tevt'

  const showModal = async () => {
    if (!verify()) return;

    setIsLoading(true);

    try {
      const paymentIntentResult = (
        await StripeServiceApi.createPaymentIntentPOST(variables, {
          amount,
          description,
        })
      )?.json;

      console.log(paymentIntentResult);

      if (paymentIntentResult.message) {
        setErrorMessage(paymentIntent.message);
        setIsLoading(false);
        return;
      }

      setClientSecret(paymentIntentResult.client_secret);
      setShowPaymentModal(true);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        onPress={showModal}
        style={StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
          alignItems: 'center',
          backgroundColor: theme.colors.branding.primary,
          flexDirection: 'row',
          fontFamily: 'System',
          fontWeight: '700',
          justifyContent: 'center',
          marginTop: 16,
        })}
        loading={isLoading}
        disabled={isLoading}
        title={'Add Balance'}
      />
      <Modal animationType={'slide'} transparent visible={showPaymentModal}>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
          }}
        >
          <Surface
            elevation={3}
            style={{
              flex: 0.4,
              backgroundColor: 'white',
              padding: 20,
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              borderTopWidth: 1,
              borderColor: theme.colors.text.light,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 8,
              }}
            >
              <Text
                accessible={true}
                style={StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'],
                  {
                    fontWeight: 'bold',
                    fontSize: 18,
                  }
                )}
              >
                Add Balance
              </Text>
              <IconButton
                icon={'AntDesign/closecircleo'}
                size={24}
                onPress={() => setShowPaymentModal(false)}
              />
            </View>

            <Elements
              stripe={stripePromise}
              options={{
                clientSecret,
                appearance: {},
              }}
            >
              <CheckoutForm
                theme={theme}
                amount={amount}
                description={description}
                navigation={navigation}
                hide={() => {
                  setShowPaymentModal(false);
                  callback?.();
                }}
              />
            </Elements>
          </Surface>
        </View>
      </Modal>
    </>
  );
};

const CheckoutForm = ({ theme, amount, description, navigation, hide }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = React.useState(null);
  const balanceServiceUpdateBalancePOST =
    BalanceServiceApi.useUpdateBalancePOST();

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      // confirmParams: {
      //   return_url: 'https://example.com/order/123/complete',
      // },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      hide();

      const balanceUpdateResult = (
        await balanceServiceUpdateBalancePOST.mutateAsync({
          amount,
          note: description,
          type: 'credit',
        })
      )?.json;

      if (balanceUpdateResult.message) {
        setErrorMessage(
          'We charged you but something went wrong while updating our records. Please contact support'
        );
        return;
      }

      navigation.navigate('BalanceScreen');
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {/* Show error message to your customers */}

      {errorMessage && <div>{errorMessage}</div>}
      <button
        id="submit"
        style={{
          boxShadow: '1px 1px 9px -1px #caefab',
          border: 'none',
          width: '100%',
          marginTop: 10,
          padding: '10px 70px',
          backgroundColor: theme.colors.branding.primary,
          borderRadius: 6,
          display: 'inline-block',
          color: palettes.App['White'],
          fontSize: 15,
          fontWeight: 'bold',
        }}
        disabled={!stripe}
      >
        {`Pay $${centToDollar(amount)}`}
      </button>
    </form>
  );
};

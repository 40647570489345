import React from 'react';
import {
  Circle,
  Icon,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { currentScreen: 'dashboard' };

const ComponentSidebarScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const getButtonColor = screenName => {
    if (props.currentScreen === screenName)
      return palettes.App['PrimarySelected'];
  };

  const getViewJustify = () => {
    return Constants?.SIDEBAR_MODE === 'full' ? 'flex-start' : 'center';
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      console.log(Constants['MA_USER_DATA']);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['View BG'],
            borderTopRightRadius: 20,
            flex: 1,
            maxWidth: 280,
            overflow: 'hidden',
          },
          dimensions.width
        )}
      >
        {/* SidebarHeader */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.branding.primary,
              minHeight: 150,
              padding: 8,
            },
            dimensions.width
          )}
        >
          {/* HeaderImage */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                marginBottom: 8,
                marginLeft: 16,
                marginRight: 16,
              },
              dimensions.width
            )}
          >
            {/* FullImage */}
            <>
              {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['LogoWhiteFull'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: 50, width: 130 }
                    ),
                    dimensions.width
                  )}
                />
              )}
            </>
            {/* CollapsedImage */}
            <>
              {!(Constants['SIDEBAR_MODE'] !== 'full') ? null : (
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['LogoWhiteSmall'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: 50, width: 50 }
                    ),
                    dimensions.width
                  )}
                />
              )}
            </>
          </View>
          {/* AdminInfoVİew */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', justifyContent: getViewJustify() },
              dimensions.width
            )}
          >
            {/* FullCircle */}
            <>
              {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                <Circle
                  {...GlobalStyles.CircleStyles(theme)['Circle'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.CircleStyles(theme)['Circle'].style,
                      {
                        backgroundColor: theme.colors.text.medium,
                        height: 85,
                        overflow: 'hidden',
                        width: 85,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <>
                    {!Constants['MA_USER_DATA']?._profile_of_user?.profile_photo
                      ?.url ? null : (
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={{
                          uri: `${Constants['MA_USER_DATA']?._profile_of_user?.profile_photo?.url}`,
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'].style,
                            { height: 85, width: 85 }
                          ),
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  <>
                    {Constants['MA_USER_DATA']?._profile_of_user?.profile_photo
                      ?.url ? null : (
                      <Icon
                        color={palettes.Brand.Surface}
                        name={'FontAwesome/user-circle'}
                        size={70}
                      />
                    )}
                  </>
                </Circle>
              )}
            </>
            {/* CollapsedCircile */}
            <>
              {!(Constants['SIDEBAR_MODE'] !== 'full') ? null : (
                <Circle
                  {...GlobalStyles.CircleStyles(theme)['Circle'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.CircleStyles(theme)['Circle'].style,
                      {
                        backgroundColor: theme.colors.text.medium,
                        height: 55,
                        overflow: 'hidden',
                        width: 55,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <>
                    {!Constants['MA_USER_DATA']?._profile_of_user?.profile_photo
                      ?.url ? null : (
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={{
                          uri: `${Constants['MA_USER_DATA']?._profile_of_user?.profile_photo?.url}`,
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'].style,
                            { height: 55, width: 55 }
                          ),
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  <>
                    {Constants['MA_USER_DATA']?._profile_of_user?.profile_photo
                      ?.url ? null : (
                      <Icon
                        color={palettes.Brand.Surface}
                        name={'FontAwesome/user-circle'}
                        size={70}
                      />
                    )}
                  </>
                </Circle>
              )}
            </>
            {/* NameView */}
            <>
              {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { justifyContent: 'center', maxWidth: 180, padding: 8 },
                    dimensions.width
                  )}
                >
                  {/* Name */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.Brand.Surface,
                          fontFamily: 'SpaceGrotesk_600SemiBold',
                          fontSize: 22,
                          marginBottom: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {Constants['MA_USER_DATA']?._profile_of_user?.name}
                  </Text>
                  {/* Handle */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.Brand.Surface,
                          fontFamily: 'SpaceGrotesk_600SemiBold',
                          fontSize: 16,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {Constants['MA_USER_DATA']?._profile_of_user?.handle}
                  </Text>
                </View>
              )}
            </>
          </View>
        </View>
        {/* SiderbarBody */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, paddingBottom: 10, paddingTop: 10 },
            dimensions.width
          )}
        >
          {/* AdminMenu */}
          <>
            {!(Constants['MA_USER_DATA']?.role === 'admin') ? null : (
              <View>
                {/* Dashboard */}
                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('DashboardScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* SiderbarButtonView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: getButtonColor('dashboard'),
                        flexDirection: 'row',
                        justifyContent: getViewJustify(),
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.Brand.Surface}
                      name={'AntDesign/dashboard'}
                      size={30}
                    />
                    <>
                      {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.Brand.Surface,
                                fontSize: 20,
                                marginLeft: 12,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Dashboard'}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
                {/* Push Notifications */}
                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('PushNotificationsScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* SiderbarButtonView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: getButtonColor('notifications'),
                        flexDirection: 'row',
                        justifyContent: getViewJustify(),
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.Brand.Surface}
                      name={'AntDesign/notification'}
                      size={30}
                    />
                    <>
                      {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.Brand.Surface,
                                fontSize: 20,
                                marginLeft: 12,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Push Notifications'}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
                {/* ApproveTags */}
                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('ApproveTagsScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* SiderbarButtonView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: getButtonColor('approve_tags'),
                        flexDirection: 'row',
                        justifyContent: getViewJustify(),
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.Brand.Surface}
                      name={'MaterialIcons/approval'}
                      size={30}
                    />
                    <>
                      {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.Brand.Surface,
                                fontSize: 20,
                                marginLeft: 12,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Approve Tags'}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
                {/* DiscoverScreen */}
                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('DiscoverScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* SiderbarButtonView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: getButtonColor('discover'),
                        flexDirection: 'row',
                        justifyContent: getViewJustify(),
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.Brand.Surface}
                      name={'MaterialIcons/explore'}
                      size={30}
                    />
                    <>
                      {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.Brand.Surface,
                                fontSize: 20,
                                marginLeft: 12,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Discover Screen'}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
                {/* ApproveAds */}
                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('ApproveAdsScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* SiderbarButtonView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: getButtonColor('approve_ads'),
                        flexDirection: 'row',
                        justifyContent: getViewJustify(),
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.Brand.Surface}
                      name={'Feather/check-square'}
                      size={30}
                    />
                    <>
                      {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.Brand.Surface,
                                fontSize: 20,
                                marginLeft: 12,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Approve Ads'}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
                {/* Ads Management */}
                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('AdsManagementScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* SiderbarButtonView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: getButtonColor('ads_management'),
                        flexDirection: 'row',
                        justifyContent: getViewJustify(),
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.Brand.Surface}
                      name={'SimpleLineIcons/badge'}
                      size={30}
                    />
                    <>
                      {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.Brand.Surface,
                                fontSize: 20,
                                marginLeft: 12,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Ads Management'}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
                {/* Balance Management */}
                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('BalanceScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* SiderbarButtonView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: getButtonColor('balance'),
                        flexDirection: 'row',
                        justifyContent: getViewJustify(),
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.Brand.Surface}
                      name={'Ionicons/wallet-outline'}
                      size={30}
                    />
                    <>
                      {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.Brand.Surface,
                                fontSize: 20,
                                marginLeft: 12,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Balance Management'}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
              </View>
            )}
          </>
          {/* BusinessMenu */}
          <>
            {!(Constants['MA_USER_DATA']?.role === 'business') ? null : (
              <View>
                {/* Dashboard */}
                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('DashboardScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* SiderbarButtonView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: getButtonColor('dashboard'),
                        flexDirection: 'row',
                        justifyContent: getViewJustify(),
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.Brand.Surface}
                      name={'AntDesign/dashboard'}
                      size={30}
                    />
                    <>
                      {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.Brand.Surface,
                                fontSize: 20,
                                marginLeft: 12,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Dashboard'}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
                {/* Ads Management */}
                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('AdsManagementScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* SiderbarButtonView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: getButtonColor('ads_management'),
                        flexDirection: 'row',
                        justifyContent: getViewJustify(),
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.Brand.Surface}
                      name={'SimpleLineIcons/badge'}
                      size={30}
                    />
                    <>
                      {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.Brand.Surface,
                                fontSize: 20,
                                marginLeft: 12,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Ads Management'}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
                {/* Balance Management */}
                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('BalanceScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* SiderbarButtonView */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: getButtonColor('balance'),
                        flexDirection: 'row',
                        justifyContent: getViewJustify(),
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.Brand.Surface}
                      name={'Ionicons/wallet-outline'}
                      size={30}
                    />
                    <>
                      {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.Brand.Surface,
                                fontSize: 20,
                                marginLeft: 12,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Balance Management'}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
              </View>
            )}
          </>
        </View>
        {/* SiderbarFooter */}
        <View
          style={StyleSheet.applyWidth(
            { borderColor: palettes.App['View BG'], borderTopWidth: 1 },
            dimensions.width
          )}
        >
          {/* LogoutButton */}
          <Pressable
            onPress={() => {
              try {
                undefined;
                undefined;
                navigation.navigate('AuthLoginScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* SiderbarButtonView */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: getViewJustify(),
                  padding: 12,
                },
                dimensions.width
              )}
            >
              <Icon
                color={palettes.Brand.Surface}
                name={'AntDesign/logout'}
                size={30}
              />
              <>
                {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.Brand.Surface,
                          fontSize: 20,
                          marginLeft: 12,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Logout'}
                  </Text>
                )}
              </>
            </View>
          </Pressable>
          {/* CollapseToggleButton */}
          <Pressable
            onPress={() => {
              try {
                if (Constants['SIDEBAR_MODE'] === 'collapsed') {
                  setGlobalVariableValue({
                    key: 'SIDEBAR_MODE',
                    value: 'full',
                  });
                }
                if (Constants['SIDEBAR_MODE'] === 'full') {
                  setGlobalVariableValue({
                    key: 'SIDEBAR_MODE',
                    value: 'collapsed',
                  });
                }
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* SiderbarButtonView */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App['View BG'],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  padding: 8,
                },
                dimensions.width
              )}
            >
              {/* CollapseIcon */}
              <>
                {!(Constants['SIDEBAR_MODE'] === 'full') ? null : (
                  <Icon
                    color={palettes.Brand.Surface}
                    name={'MaterialCommunityIcons/arrow-collapse-left'}
                    size={20}
                    style={StyleSheet.applyWidth(
                      { marginRight: 12 },
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* CollapsedIcon */}
              <>
                {!(Constants['SIDEBAR_MODE'] === 'collapsed') ? null : (
                  <Icon
                    color={palettes.Brand.Surface}
                    name={'MaterialCommunityIcons/arrow-collapse-right'}
                    size={20}
                    style={StyleSheet.applyWidth(
                      { marginRight: 12 },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </View>
          </Pressable>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ComponentSidebarScreen);

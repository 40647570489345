import React, { useEffect } from 'react';
import { View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import ComponentSidebarScreen from '../screens/ComponentSidebarScreen';

let lastPosition = false;
export function Menu(props) {
  const { collapsed } = props;
  const animatableViewRef = React.useRef(null);

  useEffect(() => {
    if (animatableViewRef.current && collapsed !== lastPosition) {
      if (collapsed) {
        animatableViewRef.current?.transition({ width: 280 }, { width: 70 });
      } else {
        animatableViewRef.current?.transition({ width: 70 }, { width: 280 });
      }
    }
    lastPosition = collapsed;
  }, [collapsed]);

  return (
    <>
      <View
        style={{
          // width:'100%',
          flex: 1,
          position: 'absolute',
          zIndex: 9999,
        }}
      ></View>
      <Animatable.View
        ref={animatableViewRef}
        style={{
          height: '100%',
          width: collapsed ? 70 : 280,
        }}
      >
        <View style={{ flex: 1 }}>
          <ComponentSidebarScreen {...props} />
        </View>
      </Animatable.View>
    </>
  );
}

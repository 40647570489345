import React from 'react';
import {
  Button,
  Checkbox,
  CheckboxRow,
  IconButton,
  NumberInput,
  Picker,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { H4 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import { ActivityIndicator, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAdminApi from '../apis/XanoAdminApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Sidebar from '../custom-files/Sidebar';
import subtract from '../global-functions/subtract';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const PushNotificationsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [bodyValue, setBodyValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [filterValue, setFilterValue] = React.useState('');
  const [idValue, setIdValue] = React.useState('');
  const [indexValue, setIndexValue] = React.useState(0);
  const [isSendingNotification, setIsSendingNotification] =
    React.useState(false);
  const [queryValue, setQueryValue] = React.useState('');
  const [screenValue, setScreenValue] = React.useState('HomepageScreen');
  const [selectedPushTokens, setSelectedPushTokens] = React.useState([]);
  const [sendToAll, setSendToAll] = React.useState(true);
  const [showConfimationModal, setShowConfimationModal] = React.useState(false);
  const [titleValue, setTitleValue] = React.useState('');
  const [checkboxRowValue, setCheckboxRowValue] = React.useState(undefined);
  const [numberInputValue, setNumberInputValue] = React.useState(undefined);
  const [pickerValue, setPickerValue] = React.useState(undefined);
  const [textInputValue, setTextInputValue] = React.useState('');
  const formatOptions = arr => {
    return arr.map(item => {
      return {
        label: item.promo_name,
        value: item.id,
      };
    });
  };

  const toggle = token => {
    setSelectedPushTokens(prev => {
      if (prev.includes(token)) return prev.filter(p => p !== token);
      else return [...prev, token];
    });
  };

  const filter = (arr, term) => {
    return arr.filter(contact => {
      return (
        contact?._profile_of_user?.name
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1 ||
        contact?._profile_of_user?.handle
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1 ||
        contact?.email.toLowerCase().indexOf(term.toLowerCase()) > -1
      );
    });
  };

  const verifyForm = () => {
    if (titleValue === '') {
      setErrorMessage('Please enter a title');
      return false;
    }
    if (bodyValue === '') {
      setErrorMessage('Please enter a body');
      return false;
    }

    setErrorMessage('');
    return true;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!Constants['MA_AUTH_TOKEN']) {
        navigation.navigate('AuthLoginScreen');
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth({ flexDirection: 'row' }, dimensions.width)}
    >
      {/* Sidebar */}
      <Utils.CustomCodeErrorBoundary>
        <Sidebar.Menu
          currentScreen="notifications"
          navigation={props.navigation}
          collapsed={Variables.SIDEBAR_MODE === 'collapsed'}
        />
      </Utils.CustomCodeErrorBoundary>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth({ flex: 1, margin: 20 }, dimensions.width)}
      >
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['ScreenHeader'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['ScreenHeader'].style,
            dimensions.width
          )}
        >
          {'Push Notifications'}
        </Text>
        {/* Container */}
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={1}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Surface'].style,
              {
                backgroundColor: palettes.Brand.Surface,
                borderRadius: 20,
                flex: 1,
                flexDirection: [
                  { minWidth: Breakpoints.Desktop, value: 'row' },
                  { minWidth: Breakpoints.Mobile, value: 'column' },
                  { minWidth: Breakpoints.Laptop, value: 'row' },
                ],
                padding: 16,
              }
            ),
            dimensions.width
          )}
        >
          {/* LeftView */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: [
                  { minWidth: Breakpoints.Desktop, value: 1 },
                  { minWidth: Breakpoints.Mobile, value: 1 },
                ],
              },
              dimensions.width
            )}
          >
            <H4
              selectable={false}
              {...GlobalStyles.H4Styles(theme)['H4'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.H4Styles(theme)['H4'].style,
                dimensions.width
              )}
            >
              {
                'You can use form below to send individual or bulk notification messages to the montage users'
              }
            </H4>
            {/* Title 2 */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['FormLabel'].style,
                  dimensions.width
                )}
              >
                {'Title'}
              </Text>
              {/* TitleInput */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTitleInputValue => {
                  try {
                    setTitleValue(newTitleInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['FormTextInput'].props}
                keyboardType={'email-address'}
                placeholder={'Enter Title'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['FormTextInput'].style,
                  dimensions.width
                )}
                value={titleValue}
              />
            </View>
            {/* Body */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
            >
              {/* Label */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['FormLabel'].style,
                  dimensions.width
                )}
              >
                {'Notification Body'}
              </Text>
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                multiline={true}
                numberOfLines={4}
                onChangeText={newTextAreaValue => {
                  const textInputValue = newTextAreaValue;
                  try {
                    setBodyValue(newTextAreaValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                textAlignVertical={'top'}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['FormTextInput'].props}
                placeholder={'Enter Message Body'}
                placeholderTextColor={theme.colors.text.light}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['FormTextInput'].style,
                  dimensions.width
                )}
                value={bodyValue}
              />
            </View>
            {/* Screen */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
            >
              {/* Label */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['FormLabel'].style,
                  dimensions.width
                )}
              >
                {'You can choose a screen to navigate on notification press'}
              </Text>
              <Picker
                autoDismissKeyboard={true}
                dropDownBackgroundColor={theme.colors.background.brand}
                dropDownBorderColor={theme.colors.border.brand}
                dropDownBorderRadius={8}
                dropDownBorderWidth={1}
                dropDownTextColor={theme.colors.text.strong}
                iconSize={24}
                leftIconMode={'inset'}
                onValueChange={newPickerValue => {
                  const pickerValue = newPickerValue;
                  try {
                    setScreenValue(newPickerValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectedIconColor={theme.colors.text.strong}
                selectedIconName={'Feather/check'}
                selectedIconSize={20}
                type={'solid'}
                {...GlobalStyles.PickerStyles(theme)['Picker'].props}
                mode={'native'}
                options={[
                  { label: 'Homepage', value: 'HomepageScreen' },
                  { label: 'Profile Screen', value: 'ProfileScreen' },
                  {
                    label: 'Tagger Profile Screen',
                    value: 'TaggerProfileScreen',
                  },
                  { label: 'Tag Page', value: 'ClickedTagPageScreen' },
                ]}
                placeholder={'Screen to Navigate'}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.PickerStyles(theme)['Picker'].style,
                    { fontSize: 13, paddingLeft: 8 }
                  ),
                  dimensions.width
                )}
                value={screenValue}
              />
            </View>
            {/* TabIndex */}
            <>
              {!['ProfileScreen', 'TaggerProfileScreen'].includes(
                screenValue
              ) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 20 },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['FormLabel'].style,
                      dimensions.width
                    )}
                  >
                    {'Initial Tab Index'}
                  </Text>
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.brand}
                    dropDownBorderColor={theme.colors.border.brand}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    onValueChange={newPickerValue => {
                      try {
                        setIndexValue(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)['Picker'].props}
                    mode={'native'}
                    options={[
                      { label: '1st Tab', value: 0 },
                      { label: '2nd Tab', value: 1 },
                      { label: '3rd Tab', value: 2 },
                    ]}
                    placeholder={'Screen to Navigate'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.PickerStyles(theme)['Picker'].style,
                        { fontSize: 13, paddingLeft: 8 }
                      ),
                      dimensions.width
                    )}
                    value={indexValue}
                  />
                </View>
              )}
            </>
            {/* Id */}
            <>
              {![
                'HomepageScreen',
                'TaggerProfileScreen',
                'ClickedTagPageScreen',
              ].includes(screenValue) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 20 },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['FormLabel'].style,
                      dimensions.width
                    )}
                  >
                    {screenValue === 'HomepageScreen'
                      ? 'Promotion ID'
                      : screenValue === 'TaggerProfileScreen'
                      ? 'Tagger ID'
                      : 'Tag ID'}
                  </Text>
                  <>
                    {!(screenValue !== 'HomepageScreen') ? null : (
                      <NumberInput
                        changeTextDelay={500}
                        onChangeText={newNumberInputValue => {
                          const numberInputValue = newNumberInputValue;
                          try {
                            setIdValue(newNumberInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        webShowOutline={true}
                        {...GlobalStyles.NumberInputStyles(theme)[
                          'Number Input'
                        ].props}
                        placeholder={'Enter an ID to be used in this screen'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.NumberInputStyles(theme)['Number Input']
                            .style,
                          dimensions.width
                        )}
                        value={idValue}
                      />
                    )}
                  </>
                  <>
                    {!(screenValue === 'HomepageScreen') ? null : (
                      <XanoAdminApi.FetchGetPromotionsGET>
                        {({ loading, error, data, refetchGetPromotions }) => {
                          const fetchData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <Picker
                              autoDismissKeyboard={true}
                              dropDownBackgroundColor={
                                theme.colors.background.brand
                              }
                              dropDownBorderColor={theme.colors.border.brand}
                              dropDownBorderRadius={8}
                              dropDownBorderWidth={1}
                              dropDownTextColor={theme.colors.text.strong}
                              iconSize={24}
                              leftIconMode={'inset'}
                              mode={'native'}
                              onValueChange={newPickerValue => {
                                try {
                                  setIdValue(newPickerValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              placeholder={'Select an option'}
                              selectedIconColor={theme.colors.text.strong}
                              selectedIconName={'Feather/check'}
                              selectedIconSize={20}
                              type={'solid'}
                              {...GlobalStyles.PickerStyles(theme)['Picker']
                                .props}
                              options={formatOptions(fetchData)}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.PickerStyles(theme)['Picker']
                                    .style,
                                  {
                                    fontSize: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 13,
                                    },
                                    paddingLeft: {
                                      minWidth: Breakpoints.Desktop,
                                      value: 8,
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                              value={idValue}
                            />
                          );
                        }}
                      </XanoAdminApi.FetchGetPromotionsGET>
                    )}
                  </>
                </View>
              )}
            </>
            {/* Query */}
            <>
              {!(screenValue === 'HomepageScreen') ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 20 },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['FormLabel'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['FormLabel'].style,
                      dimensions.width
                    )}
                  >
                    {'Search Query'}
                  </Text>
                  {/* QueryInput */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newQueryInputValue => {
                      try {
                        setQueryValue(newQueryInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['FormTextInput']
                      .props}
                    keyboardType={'email-address'}
                    placeholder={'You can choose a pre-entered query'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['FormTextInput']
                        .style,
                      dimensions.width
                    )}
                    value={queryValue}
                  />
                </View>
              )}
            </>
            {/* ErrorMessage */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { color: theme.colors.background.danger }
                ),
                dimensions.width
              )}
            >
              {errorMessage}
            </Text>
            <Button
              iconPosition={'left'}
              onPress={() => {
                try {
                  if (!verifyForm()) {
                    return;
                  }
                  setShowConfimationModal(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  { marginTop: 20 }
                ),
                dimensions.width
              )}
              title={'Send Notification'}
            />
          </View>
          {/* RightView */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: [
                  { minWidth: Breakpoints.Desktop, value: 1 },
                  { minWidth: Breakpoints.Mobile, value: 1 },
                ],
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: {
                    minWidth: Breakpoints.Desktop,
                    value: 'flex-start',
                  },
                },
                dimensions.width
              )}
            >
              <CheckboxRow
                onPress={newCheckboxRowValue => {
                  const checkboxRowValue = newCheckboxRowValue;
                  try {
                    setSendToAll(newCheckboxRowValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row'].props}
                direction={'row-reverse'}
                label={'Send Notification to all contacts'}
                status={sendToAll}
                style={StyleSheet.applyWidth(
                  GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row'].style,
                  dimensions.width
                )}
              />
            </View>
            {/* List */}
            <>
              {sendToAll ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: 1,
                      maxHeight: {
                        minWidth: Breakpoints.Desktop,
                        value: subtract(dimensions.height, 200),
                      },
                      padding: 16,
                    },
                    dimensions.width
                  )}
                >
                  {/* FilterInput */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newFilterInputValue => {
                      try {
                        setFilterValue(newFilterInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['FormTextInput']
                      .props}
                    keyboardType={'email-address'}
                    placeholder={'Search for user'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['FormTextInput']
                          .style,
                        { marginBottom: 20 }
                      ),
                      dimensions.width
                    )}
                    value={filterValue}
                  />
                  <XanoAdminApi.FetchGetUsersWithPushTokensGET>
                    {({
                      loading,
                      error,
                      data,
                      refetchGetUsersWithPushTokens,
                    }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <SimpleStyleFlatList
                          data={filter(fetchData, filterValue)}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'gUC4vvaX'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <Pressable
                                onPress={() => {
                                  try {
                                    toggle(listData?.push_token);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      marginBottom: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Checkbox
                                    defaultValue={selectedPushTokens.includes(
                                      listData?.push_token
                                    )}
                                    disabled={true}
                                  />
                                  <View>
                                    {/* Name */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            fontFamily:
                                              'SpaceGrotesk_500Medium',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?._profile_of_user?.name}
                                      {' ('}
                                      {listData?._profile_of_user?.handle}
                                      {')'}
                                    </Text>
                                    {/* Email */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          { fontSize: 13 }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.email}
                                    </Text>
                                  </View>
                                </View>
                              </Pressable>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                        />
                      );
                    }}
                  </XanoAdminApi.FetchGetUsersWithPushTokensGET>
                </View>
              )}
            </>
          </View>
        </Surface>
      </View>
      {/* ConfirmationModal */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        presentationStyle={'fullScreen'}
        transparent={true}
        visible={showConfimationModal}
      >
        <BlurView
          tint={'default'}
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          intensity={20}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
              { alignItems: 'center', justifyContent: 'center', padding: 16 }
            ),
            dimensions.width
          )}
        >
          {/* ModalContainer */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.Brand.Surface,
                borderColor: theme.colors.text.light,
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* ModalHeader */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: theme.colors.text.light,
                  flexDirection: 'row',
                  marginBottom: 8,
                  padding: 16,
                },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { flex: 1, fontFamily: 'SpaceGrotesk_600SemiBold' }
                  ),
                  dimensions.width
                )}
              >
                {'Warning !'}
              </Text>
              <IconButton
                onPress={() => {
                  try {
                    setShowConfimationModal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'AntDesign/closecircleo'}
              />
            </View>
            {/* ModalBody */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexGrow: 1, flexShrink: 0, padding: 16 },
                dimensions.width
              )}
            >
              {/* SendAllText */}
              <>
                {!sendToAll ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { marginBottom: 8, marginLeft: 16 }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'You are about to send this notification to ALL of your users. Are you sure that you want to continue? '
                    }
                  </Text>
                )}
              </>
              {/* SendToSubset */}
              <>
                {sendToAll ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { marginBottom: 8, marginLeft: 16 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'You are about to send this notification to '}
                    {selectedPushTokens?.length}
                    {' of your users. Are you sure that you want to continue? '}
                  </Text>
                )}
              </>
            </View>
            {/* ModalFooter */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderRadius: 9,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                {/* CancelButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowConfimationModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['OutlineButton'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ButtonStyles(theme)['OutlineButton'].style,
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                {/* SendNotification */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIsSendingNotification(true);
                        if (sendToAll) {
                          (
                            await XanoAdminApi.sendNotificationToAllPOST(
                              Constants,
                              {
                                body: bodyValue,
                                id: idValue,
                                index: indexValue,
                                query: queryValue,
                                screen: screenValue,
                                title: titleValue,
                              }
                            )
                          )?.json;
                        } else {
                          (
                            await XanoAdminApi.sendNotificationToSubsetPOST(
                              Constants,
                              {
                                body: bodyValue,
                                id: idValue,
                                index: indexValue,
                                push_token: selectedPushTokens,
                                query: queryValue,
                                screen: screenValue,
                                title: titleValue,
                              }
                            )
                          )?.json;
                        }

                        setTitleValue('');
                        setBodyValue('');
                        setQueryValue('');
                        setIndexValue('');
                        setScreenValue('HomepageScreen');
                        setFilterValue('');
                        setIdValue('');
                        setIsSendingNotification(false);
                        setShowConfimationModal(false);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={isSendingNotification}
                  loading={isSendingNotification}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { backgroundColor: palettes.App.Warning }
                    ),
                    dimensions.width
                  )}
                  title={'Yes, Send'}
                />
              </View>
            </View>
          </View>
        </BlurView>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(PushNotificationsScreen);

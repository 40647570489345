import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

import palettes from './themes/palettes';

export const BlurViewStyles = theme =>
  StyleSheet.create({
    'Blur View': {
      style: { flexBasis: 0, flexGrow: 1, flexShrink: 1 },
      props: {},
    },
  });

export const LinearGradientStyles = theme =>
  StyleSheet.create({ 'Linear Gradient': { style: { flex: 1 }, props: {} } });

export const H1Styles = theme =>
  StyleSheet.create({
    H1: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 32,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const SurfaceStyles = theme =>
  StyleSheet.create({
    BalanceCard: {
      style: {
        backgroundColor: palettes.Brand.Surface,
        borderRadius: 16,
        margin: 16,
        marginRight: 8,
        minHeight: 40,
        minWidth: 180,
        padding: 16,
      },
      props: {},
    },
    Surface: { style: { minHeight: 40 }, props: {} },
  });

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { style: { height: 100, width: 100 }, props: {} },
  });

export const ImageBackgroundStyles = theme =>
  StyleSheet.create({ 'Image Background': { style: { flex: 1 }, props: {} } });

export const CircleStyles = theme =>
  StyleSheet.create({
    Circle: {
      style: {
        alignItems: 'center',
        backgroundColor: theme.colors.branding.primary,
        justifyContent: 'center',
      },
      props: {},
    },
  });

export const TextStyles = theme =>
  StyleSheet.create({
    FormLabel: {
      style: {
        color: theme.colors.text.strong,
        fontFamily: [
          { minWidth: Breakpoints.Laptop, value: 'SpaceGrotesk_400Regular' },
          { minWidth: Breakpoints.Mobile, value: 'SpaceGrotesk_400Regular' },
        ],
        fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
      },
      props: {},
    },
    ScreenHeader: {
      style: {
        color: [
          {
            minWidth: Breakpoints.Desktop,
            value: palettes.Brand['Strong Inverse'],
          },
          {
            minWidth: Breakpoints.Mobile,
            value: palettes.Brand['Strong Inverse'],
          },
        ],
        fontFamily: [
          { minWidth: Breakpoints.Mobile, value: 'SpaceGrotesk_600SemiBold' },
          { minWidth: Breakpoints.Desktop, value: 'SpaceGrotesk_600SemiBold' },
          { minWidth: Breakpoints.Laptop, value: 'SpaceGrotesk_400Regular' },
        ],
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 30 },
          { minWidth: Breakpoints.Desktop, value: 30 },
        ],
        marginBottom: [
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.Mobile, value: 20 },
        ],
      },
      props: {},
    },
    TableHeaderCellText: {
      style: {
        color: theme.colors.text.strong,
        fontFamily: 'SpaceGrotesk_700Bold',
      },
      props: {},
    },
    Text: {
      style: {
        color: theme.colors.text.strong,
        fontFamily: 'SpaceGrotesk_400Regular',
      },
      props: {},
    },
  });

export const H2Styles = theme =>
  StyleSheet.create({
    H2: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 24,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const TableStyles = theme =>
  StyleSheet.create({ Table: { style: { flex: 1 }, props: {} } });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { style: { minHeight: 40 }, props: {} } });

export const VideoPlayerStyles = theme =>
  StyleSheet.create({ Video: { style: { height: 215 }, props: {} } });

export const H3Styles = theme =>
  StyleSheet.create({
    H3: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 18.72,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    FormTextInput: {
      style: {
        backgroundColor: palettes.Brand.Surface,
        borderBottomWidth: 1,
        borderColor: [
          { minWidth: Breakpoints.Mobile, value: theme.colors.text.light },
          { minWidth: Breakpoints.Laptop, value: theme.colors.text.light },
        ],
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        fontFamily: [
          { minWidth: Breakpoints.Laptop, value: 'SpaceGrotesk_400Regular' },
          { minWidth: Breakpoints.Mobile, value: 'SpaceGrotesk_400Regular' },
        ],
        fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
        marginTop: 10,
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: { placeholderTextColor: theme.colors.text.light },
    },
    'Text Area': {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    'Text Input': {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        fontFamily: 'SpaceGrotesk_400Regular',
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: { placeholderTextColor: theme.colors.text.light },
    },
  });

export const CheckboxRowStyles = theme =>
  StyleSheet.create({
    'Checkbox Row': {
      style: {
        marginLeft: { minWidth: Breakpoints.Desktop, value: -16 },
        marginTop: { minWidth: Breakpoints.Desktop, value: -4 },
        minHeight: 50,
      },
      props: {},
    },
  });

export const ActivityIndicatorStyles = theme =>
  StyleSheet.create({
    'Activity Indicator': { style: { height: 36, width: 36 }, props: {} },
  });

export const LinkStyles = theme =>
  StyleSheet.create({
    Link: { style: { color: theme.colors.branding.primary }, props: {} },
  });

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      style: {
        backgroundColor: theme.colors.branding.primary,
        borderRadius: 8,
        fontFamily: 'SpaceGrotesk_600SemiBold',
        textAlign: 'center',
      },
      props: {},
    },
    OutlineButton: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: [
          {
            minWidth: Breakpoints.Desktop,
            value: theme.colors.branding.primary,
          },
          {
            minWidth: Breakpoints.Mobile,
            value: theme.colors.branding.primary,
          },
        ],
        borderRadius: 8,
        borderWidth: 1.5,
        color: theme.colors.branding.primary,
        fontFamily: 'System',
        fontWeight: '700',
        textAlign: 'center',
      },
      props: {},
    },
  });

export const ViewStyles = theme =>
  StyleSheet.create({
    FormFieldView: {
      style: {
        alignItems: [
          { minWidth: Breakpoints.Desktop, value: 'center' },
          { minWidth: Breakpoints.Mobile, value: 'center' },
        ],
        flexDirection: [
          { minWidth: Breakpoints.Desktop, value: 'row' },
          { minWidth: Breakpoints.Mobile, value: 'row' },
        ],
        marginBottom: { minWidth: Breakpoints.Desktop, value: 8 },
      },
      props: {},
    },
    HeaderViewCell: {
      style: {
        flex: 1,
        paddingBottom: 4,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 4,
      },
      props: {},
    },
  });

export const TableCellStyles = theme =>
  StyleSheet.create({
    'Table Cell': {
      style: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        minWidth: 125,
        overflow: 'hidden',
      },
      props: { borderStyle: 'dashed', drawEndBorder: false },
    },
    TableHeaderCell: {
      style: {
        backgroundColor: palettes.Brand.Surface,
        flex: 1,
        flexDirection: 'row',
        minWidth: 125,
      },
      props: {},
    },
  });

export const SliderStyles = theme =>
  StyleSheet.create({
    Slider: { style: { marginLeft: 12, marginRight: 12 }, props: {} },
  });

export const SwipeableItemStyles = theme =>
  StyleSheet.create({
    'Swipeable Item': { style: { overflow: 'hidden' }, props: {} },
  });

export const PickerStyles = theme =>
  StyleSheet.create({
    Picker: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        fontFamily: 'SpaceGrotesk_400Regular',
        marginTop: 10,
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {
        dropDownBackgroundColor: palettes.Brand.Surface,
        selectedIconColor: palettes.Brand.Surface,
      },
    },
    TablePicker: {
      style: {
        backgroundColor: palettes.Brand.Surface,
        borderColor: theme.colors.border.brand,
        fontFamily: 'SpaceGrotesk_400Regular',
        height: 35,
        marginTop: 0,
        padding: 4,
        paddingBottom: 6,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 6,
      },
      props: {
        dropDownBackgroundColor: palettes.Brand.Surface,
        placeholderTextColor: theme.colors.text.light,
        selectedIconColor: palettes.Brand.Surface,
      },
    },
  });

export const WebViewStyles = theme =>
  StyleSheet.create({ 'Web View': { style: { flex: 1 }, props: {} } });

export const SwiperStyles = theme =>
  StyleSheet.create({
    Swiper: { style: { height: 300, width: '100%' }, props: {} },
  });

export const H4Styles = theme =>
  StyleSheet.create({
    H4: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 16,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const NumberInputStyles = theme =>
  StyleSheet.create({
    'Number Input': {
      style: {
        backgroundColor: palettes.Brand.Surface,
        borderBottomWidth: 1,
        borderColor: theme.colors.text.light,
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
        marginTop: 10,
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: { placeholderTextColor: theme.colors.text.light },
    },
  });

export const IconButtonStyles = theme =>
  StyleSheet.create({
    test: {
      style: {},
      props: { color: theme.colors.background.danger, size: 20 },
    },
  });
